import React, { useEffect, useState, useCallback } from 'react';
import {
  Select, Form, Spin,
} from 'antd';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';
import { useFetch } from '../hooks'

const hogan = require('hogan.js');

const { Option } = Select;

const OptionList = ({
  action = '',
  id = '',
  title = '',
  filterOptions = {},
  name = '',
  mode,
  initialValue = undefined,
  required = true,
  optionLabelFormat = '',
  optionLabelData = [],
  disabled = false
}) => {
  const { t, i18n } = useTranslation()

  const [dataList] = useFetch({
    url: action
  })

  const renderTemplate = (item) => {
    const data = {}
    _.map(optionLabelData, (key) => {
      data[key] = (item[key]) || 'N/A'
    })
    const template = hogan.compile(optionLabelFormat);
    const output = template.render(data);
    return output
  }

  return (
    <Form.Item
      name={name}
      label={t(title)}
      initialValue={initialValue}
      rules={[{ required, message: t('input_required') }]}
    >
      <Select
        style={{ width: '100%' }}
        placeholder={t('select')}
        optionFilterProp="label"
        showSearch
        allowClear
        mode={mode}
        disabled={disabled}
      >
        {_.map(_.filter(dataList, filterOptions), (item) => {
          return (
            <Option
              key={item[id]}
              value={item[id]}
              label={renderTemplate(item)}
            >
              {renderTemplate(item)}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default OptionList;
