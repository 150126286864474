import _ from 'lodash';

import * as coachApi from './coach';
import * as locationApi from './location';
import * as courseApi from './course';
import * as initApi from './init';
import * as attendanceApi from './attendance';
import * as loginApi from './login';
import * as SiteMetaApi from './site_meta';
import * as StudentApi from './user';
import * as NotificationApi from './notification';

export default _.assign(
  coachApi,
  locationApi,
  courseApi,
  initApi,
  attendanceApi,
  loginApi,
  SiteMetaApi,
  StudentApi,
  NotificationApi
);
