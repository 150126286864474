import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  message,
  Input,
  Modal,
  notification,
  Row,
  Col,
  Radio,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import _, { values } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import FormUploadFile from '../form/FormUploadFile';
import moment from "moment";
import { defaultDateTimeFormat } from "../../styles/DisplayFormat";

const BlogModal = (props) => {
  const {
    dataObj,
    setDataObj,
    modalVisible,
    setModalVisible,
  } = props;
  
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      centered
      title={`${action === 'post' ? t('create') : t('edit')} ${t("blog")}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setDataObj({})
        setModalVisible(false);
      }}
    >
      <BlogForm {...props} action={action} />
    </Modal>
  );
};

const BlogForm = (props) => {
  const {
    dataObj, action, setModalVisible, setCallRefresh
  } = props;
  const [form] = Form.useForm();
  const app = useSelector((state) => state.app)
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [newImage, setNewImage] = useState({
    filename: '',
    filepath: null,
  })

  useEffect(() => {
    console.log(dataObj)

    if (!_.isEmpty(dataObj)) {
      const formObj = _.clone(dataObj);
      // formObj.published_date = moment.unix(dataObj.published_date);
      form.setFieldsValue(formObj)
      dataObj.published_date = 1;
    }
  }, [dataObj]);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const itemObj = {
      ...values, 
    };
    
    if (action === 'put') { 
      itemObj.blog_id = dataObj.blog_id;
    }

    itemObj.image_attachment = newImage.filepath;
    itemObj.published_date = 1;

    const resp = await Service.call(action, '/api/admin/blog', itemObj)
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    setCallRefresh(true);
  };

  const onUploadChange = async (info) => {
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success(t('success'))
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        }
        setNewImage(data)
      } else {
        message.error(t('fail'))
      }
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="blog_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label={t('blog_title')}
            name="blog_title"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('blog_content')}
            name="blog_content"
            rules={[{ required: false, message: t('input_required') }]}
          >
            <Input.TextArea showCount />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('status')}
            name="is_active"
          >
            <Radio.Group
              options={[
                {label: t('active'), value: 1},
                {label: t('inactive'), value: 0}
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('image')}
            name="image_attachment"
          >
            <FormUploadFile
              onChange={onUploadChange}
              type="one"
              imageURL={newImage.filepath ? `${app.config.STATIC_SERVER_URL}/${newImage.filepath}` : null}
              data={{ resize: true }}
            />
          </Form.Item>
        </Col>

        {/* <Col span={16}>
          <Form.Item
            label={t('published_date')}
            name="published_date"
          >
            <DatePicker
              showTime
              format={defaultDateTimeFormat}
              style={{ width: '100% '}}
            />
          </Form.Item>
        </Col> */}

      </Row>

      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default BlogModal;
