import React, { useState, useEffect } from 'react'
import {
  Layout, Menu, Popconfirm, Modal, Icon
} from 'antd';
import {
  BrowserRouter as Router,
  Redirect,
  Link,
  useHistory
} from "react-router-dom";
import {
  DashboardOutlined,
  AppstoreOutlined,
  TeamOutlined,
  BookOutlined,
  NotificationOutlined,
  MinusOutlined,
  LogoutOutlined,
  CarOutlined,
  GatewayOutlined,
  ThunderboltOutlined,
  ProfileOutlined,
  TagOutlined,
  GiftOutlined,
  CreditCardOutlined,
  ClusterOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  CalendarOutlined,
  DollarOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  TagsOutlined,
  ShopOutlined,
  TrophyOutlined,
  UserOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAdmin, setAuth } from '../redux/actions/common'
import * as Service from '../core/Service';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;

function Sidebar({
  defaultSelectedKeys, defaultOpenKeys
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector((state) => state.app)
  const config = useSelector((state) => state.app.config);
  const admin = useSelector((state) => state.app.admin);
  const [logout, setLogout] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (logout) return history.push('/admin/login')
  }, [logout])

  const showConfirm = () => {
    confirm({
      centered: true,
      title: t('confirm_logout'),
      icon: <ExclamationCircleOutlined />,
      cancelText: t('cancel'),
      okText: t('ok'),
      onOk() {
        handleLogout();
      }
    });
  }

  const handleLogout = async () => {
    await Service.logout();
    dispatch(setAdmin({}))
    dispatch(setAuth(false))
    setLogout(false)
  }

  const renderMenuItem = () => {
    return _.map(dataList, (item) => {
      if (item.subMenus && (item.privilege === 'all' || _.includes(item.privilege, app.admin.role))) {
        return (
          <SubMenu
            key={item.key}
            icon={item.icon}
            title={t(item.key)}
          >
            {_.map(item.subMenus, ({
              key, path, icon, privilege
            }) => {
              if (privilege === 'all' || _.includes(privilege, app.admin.role)) {
                return (
                  <Menu.Item
                    key={key}
                    style={{
                      height: "40px",
                      lineHeight: "40px"
                    }}
                    icon={<MinusOutlined />}
                  >
                    <Link to={path}>
                      {icon}
                      {t(key)}
                    </Link>
                  </Menu.Item>
                )
              }
            })}
          </SubMenu>
        )
      }
      if (item.privilege === 'all' || _.includes(item.privilege, app.admin.role)) {
        return (
          <Menu.Item
            key={item.key}
            className={
              item.className ? item.className.join(' ') : ''
            }
          >
            <Link to={item.path}>
              {item.icon}
              <span>{t(item.key)}</span>
            </Link>
          </Menu.Item>
        )
      }
    })
  }

  return (
    <Sider
      breakpoint="sm"
      collapsedWidth="0"
      width={250}
      style={{
        height: '100%', opacity: 1, position: 'relative', backgroundColor: 'white',
      }}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[defaultSelectedKeys]}
        defaultOpenKeys={[defaultOpenKeys]}
        style={{ padding: '10px 0px', minHeight: '100%' }}
      >
        {renderMenuItem()}
        <Menu.Divider />
        <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={showConfirm}>
          <span>{t('logout')}</span>
        </Menu.Item>
        <Menu.Item key="version" disabled>
          <span>{`${t('version')} - `}</span>
          <span>{config.version}</span>
        </Menu.Item>
      </Menu>
    </Sider>
  )
}

let dataList = [
  {
    key: 'dashboard',
    path: '/admin/home',
    icon: <DashboardOutlined />,
    display: 'block',
    privilege: 'all'
  },
  // {
  //   key: 'location',
  //   path: '/admin/location',
  //   icon: <ShopOutlined />,
  //   display: 'block',
  //   privilege: 'all'
  // },
  {
    key: 'user_apply',
    path: '/admin/user/apply',
    icon: <DashboardOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'course',
    path: '/admin/course',
    icon: <DashboardOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'user',
    path: '/admin/user/list',
    icon: <UserOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'attendance',
    path: '/admin/attendance',
    icon: <DashboardOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'admin',
    path: '/admin/coach',
    icon: <UserOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'blog',
    path: '/admin/blog',
    icon: <BookOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'notification',
    path: '/admin/notice',
    icon: <NotificationOutlined />,
    display: 'block',
    privilege: 'all'
  },
  {
    key: 'site_meta',
    path: '/admin/app_config/site_meta',
    icon: <AppstoreOutlined />,
    display: 'block',
    privilege: 'all'
  },
]

export default Sidebar;
