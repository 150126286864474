import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  Tabs,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import Joi from 'joi';
import _, { isNull, values } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setAdmin, setAuth } from '../../redux/actions/common'
import * as Service from "../../core/Service";
import OptionList from '../OptionList';
import * as FormOptions from '../../core/helper/FormOptions';

const { Option } = Select;
const { TabPane } = Tabs;
const OPTIONS = [];

const UserApplyModal = (props) => {
  const {
    dataObj,
    setDataObj,
    modalVisible,
    setModalVisible,
    userList,
  } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      centered
      destroyOnClose
      title={`${action === 'post' ? t('create') : t('edit')} ${t('user_apply_form')}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setDataObj({})
        setModalVisible(false);
      }}
    >
      <UserApplyForm {...props} action={action} />
    </Modal>
  );
};

const UserApplyForm = (props) => {
  const {
    profile, dataObj, action, userType, setModalVisible, setCallRefresh, userList,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    form.setFieldsValue(dataObj);
  }, []);

  const typeSelector = () => {
    let options = []
    _.each(userType, (value, key) => {
      if (value === 'User') {
        return
      }
      options.push(<Option value={value} key={key}>{value}</Option>);
    })
    return options;
  }

  const prefixSelector = (
    <Form.Item name="phonePrefix" noStyle initialValue="+852">
      <Select disabled={!_.isEmpty(dataObj)} style={{ width: 80 }}>
        <Option value="+852">+852</Option>
      </Select>
    </Form.Item>
  );

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const {apply_course_id, ...itemObj} = values;
    itemObj.is_active = 1;

    let endpoint = ''
    if (action === 'post') {
      endpoint = '/api/admin/user/apply/list'
    } else {
      endpoint = `/api/admin/user/apply/list/${values.apply_course_id}`
    }

    const resp = await Service.call(action, endpoint, itemObj)
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    if (!profile) {
      setCallRefresh(true);
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="user_apply_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            name="apply_course_id"
            hidden
          />
        </Col>
        <Col span={24}>
          <OptionList
            required
            action="/api/admin/user"
            id="user_id"
            name="user_id"
            optionLabelFormat="ID: {{user_no}} | {{phone}} | Name: {{first_name}} {{last_name}}"
            optionLabelData={['user_no', 'phone', 'first_name', 'last_name']}
            title={t('student_name')}
            disabled={!_.isEmpty(dataObj)}
          />
        </Col>
        <Col span={12}>
          <OptionList
            required
            action="/api/admin/course"
            id="course_id"
            name="course_id"
            optionLabelFormat="ID: {{course_code}} | {{course_name}}"
            optionLabelData={['course_code', 'course_name']}
            title={t('course_code')}
            disabled={!_.isEmpty(dataObj)}
          />
        </Col>
        {/* <Col span={4}>
          <Form.Item
            label={t('address')}
            name="address"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('age')}
            name="age"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <InputNumber />
          </Form.Item>
        </Col> */}
        {/* <Col span={8}>
          <Form.Item
            // TODO: translation
            label={t('gender')}
            name="gender"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.genderOptions()}
            />
          </Form.Item>
        </Col> */}

        <Col span={6}>
          <Form.Item
            label={t('payment_status')}
            name="status"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.isActiveOptions()}
            />
          </Form.Item>
        </Col>

      </Row>
      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserApplyModal;
