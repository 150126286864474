import React, { useState, useEffect } from 'react';
import {
  Spin, Button, Table, Tooltip, Typography, Tabs, Image, Row, Col, Divider, Popconfirm, notification
} from 'antd';
import { EditOutlined, ClearOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Main from "../core/Main";
import apiRoutes from "../core/api";
import * as Helper from "../core/helper/RenderDisplay";
import SiteMetaModal from './SiteMetaModal';
import { useFetch } from '../hooks';
import SearchBar from './SearchBar';


const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

const tableIDName = "site_meta_id";
const { Paragraph } = Typography;

const { TabPane } = Tabs;

const SystemConfigTable = (props) => {
  const { t } = useTranslation()

  const app = useSelector((state) => state.app)
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState({});

  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/site_meta',
    // order: { key: ['status', 'ctime'], value: ['desc', 'asc']},
    callRefresh,
    setCallRefresh
  })


  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        key: tableIDName,
        render: (value, record,) => {
          return (
            <Row gutter={12}>
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setSelectedRecord(record);
                      setModalVisible(true)
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={t('Clear_Value')}>
                  <Popconfirm
                    title={t('confirm_delete')}
                    placement="right"
                    onConfirm={async () => {
                      if (!record.meta_value) return;
                      const resp = await apiRoutes.putSiteMeta(value, {
                        meta_value: ''
                      })
                      if (resp.status <= 0) {
                        return;
                      }
                      setRefresh(true)
                    }}
                    cancelText={t('cancel')}
                    okText={t('ok')}
                  >
                    <Button
                      shape="circle"
                      icon={<ClearOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>

              </Col>
            </Row>
          )
        }
      },
      {
        title: t('meta_key'),
        dataIndex: 'meta_key',
        render: (value) => t(value)
      },
      {
        title: t('value'),
        dataIndex: 'meta_value',
        render: (value, type) => {
          if (type.type === 'image') {
            if (value == null || value === '') return;
            return (<Image width={100} src={`${app.config.STATIC_SERVER_URL}/${value}`} />)
          }
          if (type.type === 'editor') {
            return (
              <Paragraph style={{
                maxWidth: 500,
                maxHeight: 300,
                marginBottom: 0,
                textAlign: 'justify',
                overflow: 'scroll'
              }}
              >
                {htmlToReactParser.parse(value)}
              </Paragraph>
            )
          }

          return value
        },
        width: 400
      },
      {
        title: t('created_time'),
        dataIndex: 'ctime',
        render: (value) => Helper.displayMoment(value),
        sorter: (a, b) => a.ctime - b.ctime
      },
      {
        title: t('updated_time'),
        dataIndex: 'utime',
        render: (value) => Helper.displayMoment(value),
        sorter: (a, b) => a.utime - b.utime
      },
    ];
    return columns;
  }

  return (
    <Spin
      indicator={(
        <svg
          className="spinner"
          viewBox="0 0 50 50"
        >
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
        </svg>
      )}
      spinning={callRefresh}
    >
      <Row>
        <SearchBar
          fields={[
            'site_meta_id',
            'meta_value',
            'meta_key',
          ]}
          data={dataList}
          setData={setDataList}
          refresh={refresh}
        />
      </Row>
      <Divider />
      <Tabs
        type="card"
      >
        {_.map(_.groupBy(dataList, 'category'), (data, type) => {
          return (
            <TabPane tab={t(type)} key={type}>

              <Table
                locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
                rowKey={tableIDName}
                scroll={{ x: 'max-content' }}
                dataSource={data}
                columns={setTableHeader()}
                bordered
              />
            </TabPane>
          )
        })}
      </Tabs>
      <SiteMetaModal
        selectedRecord={selectedRecord}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </Spin>
  )
}
export default SystemConfigTable;
