import { Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import apiRoutes from '../../core/api';

const CourseStudentList = (props) => {
  const {callRefresh} = props;

  const {t} = useTranslation();

  const params = useParams();
  const location = useLocation();
  const {courseCode} = location.state || params;

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getDataList();
  }, [courseCode, callRefresh]);

  const getDataList = async () => {
    const resp = await apiRoutes.getCourseStudentList(courseCode);
    setDataList(resp)
  }

  const getTableColumns = () => {
    const list = [{
      title: t('user_id'),
      dataIndex: `user_no`,
      sorter: (a, b) => a.user_no.localeCompare(b.user_no),
    },
    {
      title: t('name'),
      dataIndex: `first_name`,
      render: (val, record) => {
        return `${record?.first_name ?? ''} ${record?.last_name ?? ''}`
      }
    },
    {
      title: t('age'),
      dataIndex: `age`,
    },
    {
      title: t('mobile'),
      dataIndex: 'phone',
    },
    {
      title: t('email'),
      dataIndex: 'email',
    },];


    return list;
  }

  return (
    <Table
      columns={getTableColumns()}
      dataSource={dataList}
      scroll={{x: 'max-content'}}
    />
  )
};

export default CourseStudentList;
