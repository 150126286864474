import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Divider
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons'
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as debugLib from 'debug';
import { useSelector } from 'react-redux';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { FaqTable, FaqModal } from '../../components/faq';

const debug = debugLib('app:pages/FaqList');

const selectedKey = "faq";
const openKey = "faq";

const FaqList = (props) => {
  const { t } = useTranslation();
  const title = t('faq');
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Row>
        <Col>
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              setDataObj({})
              setModalVisible(true)
            }}
          >
            {t('create')}
          </Button>
        </Col>
      </Row>
      <Divider />

      <FaqTable
        setModalVisible={setModalVisible}
        setDataObj={setDataObj}
        callRefresh={callRefresh}
        setCallRefresh={setCallRefresh}
      />
      <FaqModal
        dataObj={dataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        callRefresh={callRefresh}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default FaqList;
