import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Button, Form, Input, Select, Tabs, Space, message
} from 'antd';
import apiRoutes from '../../core/api';
import { formItemLayout, tailLayout } from '../../styles/ModalLayout';
import { useTranslation } from 'react-i18next';

import FormUploadFile from '../form/FormUploadFile';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const hasImage = false; // For Control the form open Image input
const setting = { // For Control the form open User/Group Form
  group: false,
  user: false
}

const NotificationForm = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // Get param from props
  const { data, refresh } = props;

  // const [loading, setLoading] = useState(false);
  const [postImageURL, setPostImageURL] = useState("");
  const [postCoverImagePath, setPostCoverImagePath] = useState(null);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    // init
    form.resetFields();
    setPostImageURL("");
    setPostCoverImagePath(null);

    // when close form
    return () => {
      setPostImageURL("");
      setPostCoverImagePath(null);
      form.resetFields();
    };
  }, [data]);

  const onFinish = async (postObj) => {
    postObj.image_attachment = postCoverImagePath;

    let apiFunction = apiRoutes.postGlobalNotification
    if (selectedTab === "user") {
      postObj.type = postObj.type.value;
      apiFunction = apiRoutes.postUserNotification;
    } else if (selectedTab === 'group') {
      apiFunction = apiRoutes.postGroupNotification;
    }

    // POST data
    let result = await apiFunction(postObj);
    if (result.errorMessage) {
      message.error(result.errorMessage);
    }

    message.success('success');
    return refresh();
  };

  function onChange(key) {
    // console.log(key);
    setSelectedTab(key);
    form.resetFields();

    if (key === 'all') {
      form.setFieldsValue({
        type: "all"
      });
    } else {
      form.setFieldsValue({
        type: ""
      });
    }
  }

  const onRemove = (file) => {
    setPostImageURL('');
    setPostCoverImagePath(null);
  }

  const uploadOnChange = (info) => {
    // console.log('props', props);
    console.log('info >>>>>>>>>>>>>>>>', info);
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy();
      if (response.status > 0) {
        message.success(t('common:Uploaded'))
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        }
        console.log('data >>>>>>>>>>>>>>', data);
        let path = info.file.response.filepath
        let url = info.file.response.url
        setPostImageURL(url);
        setPostCoverImagePath(data.filepath);
      } else {
        message.error(t('common:Failed'))
      }
    }
  }


  return (
    <Tabs
      defaultActiveKey="all"
      onChange={onChange}
      centered
    >
      <TabPane tab="全部用戶" key="all">
        <GlobalForm
          form={form}
          onFinish={onFinish}
          uploadOnChange={uploadOnChange}
          onRemove={onRemove}
          postCoverImagePath={postCoverImagePath}
        />
      </TabPane>
      {setting.group && (
        <TabPane tab="群組" key="group">
          <GroupForm
            form={form}
            callRefresh={selectedTab === 'group'}
            onFinish={onFinish}
            uploadOnChange={uploadOnChange}
            onRemove={onRemove}
            postCoverImagePath={postCoverImagePath}
          />
        </TabPane>
      )}
      {setting.user && (
        <TabPane tab="個別用戶" key="user">
          <UserForm
            form={form}
            callRefresh={selectedTab === 'user'}
            onFinish={onFinish}
            uploadOnChange={uploadOnChange}
            onRemove={onRemove}
            postCoverImagePath={postCoverImagePath}
          />
        </TabPane>
      )}
    </Tabs>
  )
}

const GlobalForm = (props) => {
  const { form, onFinish, uploadOnChange, onRemove, postCoverImagePath } = props;

  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        name="notification_create_form"
        onFinish={onFinish}
        initialValues={
          { type: 'all' }
        }
      >

        <Form.Item
          label="群組"
          name="type"
          rules={[{
            required: true,
            message: "請選擇群組"
          }]}
          hidden
        >
          <Select disabled>
            <Option value="all">全部</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="標題"
          name="title"
          rules={[{
            required: true,
            message: "請填寫標題"
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="內容"
          name="content"
          rules={[{
            required: true,
            message: "請填寫內容"
          }]}
        >
          <TextArea rows={7} />
        </Form.Item>

        {hasImage && (
          <Form.Item
            label="圖片"
            name="image_attachment"
          >
            <FormUploadFile
              type="one"
              data={{ scope: "public" }}
              onChange={uploadOnChange}
              onRemove={onRemove}
              imageURL={postCoverImagePath}
            />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">提交</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

const GroupForm = (props) => {
  const { form, callRefresh, onFinish, uploadOnChange, onRemove, postCoverImagePath } = props;
  const [userGroupData, setUserGroupData] = useState([]);

  useEffect(() => {
    if (callRefresh) getUserGroupData()
  }, [callRefresh]);

  const getUserGroupData = async () => {
    let result = await apiRoutes.getNotificationGroupSelectionList();
    setUserGroupData(result);
  }

  const userGroupListItems = _.map(userGroupData, (v, k) => (
    <Option key={v.user_tag_id} value={v.tag_name}>
      {v.tag_name}
    </Option>
  ));

  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        name="notification_form"
        onFinish={onFinish}
      >

        <Form.Item
          label="群組"
          name="type"
          rules={[{
            required: true,
            message: "請選擇群組"
          }]}
        >
          <Select>
            {userGroupListItems}
          </Select>
        </Form.Item>

        <Form.Item
          label="標題"
          name="title"
          rules={[{
            required: true,
            message: "請填寫標題"
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="內容"
          name="content"
          rules={[{
            required: true,
            message: "請填寫內容"
          }]}
        >
          <TextArea rows={7} />
        </Form.Item>

        {hasImage && (
          <Form.Item
            label="圖片"
            name="image_attachment"
          >
            <FormUploadFile
              type="one"
              data={{ scope: "public" }}
              onChange={uploadOnChange}
              onRemove={onRemove}
              imageURL={postCoverImagePath}
            />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">提交</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

const UserForm = (props) => {
  const { form, callRefresh, onFinish, uploadOnChange, onRemove, postCoverImagePath } = props;

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (callRefresh) getUserData()
  }, [callRefresh]);

  const getUserData = async () => {
    let result = await apiRoutes.getNotificationUserSelectionList();
    setUserData(result);
  }

  const userlistItems = _.map(userData, (user, index) => (
    <Option key={`notice_user_select_${index}`} value={user.user_id}>
      {user.nickname}
      ({user.display_id})
    </Option>
  ));
  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        name="notification_form"
        onFinish={onFinish}
        initialValues={{ type: '' }}
      >

        <Form.Item
          label="用戶"
          name="type"
          rules={[{
            required: true,
            message: "請選擇用戶"
          }]}
        >
          <Select
            showSearch
            labelInValue
            // optionFilterProp="children"
            // filterOption={(input, option) =>
            //   option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            //   || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
            filterOption={(input, option) => {
              return option.children.join("").includes(input);
            }}
          >
            {userlistItems}
          </Select>
        </Form.Item>

        <Form.Item
          label="標題"
          name="title"
          rules={[{
            required: true,
            message: "請填寫標題"
          }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="內容"
          name="content"
          rules={[{
            required: true,
            message: "請填寫內容"
          }]}
        >
          <TextArea rows={7} />
        </Form.Item>

        {hasImage && (
          <Form.Item
            label="圖片"
            name="image_attachment"
          >
            <FormUploadFile
              type="one"
              data={{ scope: "public" }}
              onChange={uploadOnChange}
              onRemove={onRemove}
              imageURL={postCoverImagePath}
            />
          </Form.Item>
        )}
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">提交</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default NotificationForm;
