import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin, Input, Modal, Form, notification, TimePicker, Radio
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import * as Helper from '../../core/helper/RenderDisplay';
import * as Options from '../../core/helper/FormOptions';
import apiRoutes from '../../core/api';
import { defaultDateFormat, defaultTimeFormat } from '../../styles/DisplayFormat';

const tableIDName = "lesson_id";

const LessonAttendanceTable = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const {
    dataList,
    loading = false,
    refresh,
    displayCourse = true,
    displayLesson = true,
    displayStudent = true,
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const setAttendanceTableHeader = () => {
    const courseCol = [
      {
        title: t('course_code'),
        dataIndex: `course_code`,
      },
      {
        title: t('course_name'),
        dataIndex: `course_name`,
      }
    ];

    const lessonCol = [
      {
        title: t('date'),
        dataIndex: `start_datetime`,
        render: (value) => Helper.displayDate(value)
      },
      {
        title: t('start_time'),
        dataIndex: `start_datetime`,
        render: (value) => Helper.displayTime(value)
      },
      {
        title: t('end_time'),
        dataIndex: `end_datetime`,
        render: (value) => Helper.displayTime(value)
      }
    ];

    const userCol = [
      {
        title: t('user_id'),
        dataIndex: `user_no`,
      },
      {
        title: t('nickname'),
        dataIndex: `nickname`,
      },
      {
        title: t('name'),
        dataIndex: `user_id`,
        render: (value, record) => `${record.first_name} ${record.last_name}`
      },
    ];

    return _.concat(
      [{
        title: t('operation'),
        dataIndex: `lesson_id`,
        render: (value, record) => (
          <Button
            shape="circle"
            style={{ color: '#000000' }}
            icon={<EditOutlined />}
            onClick={() => {
              form.setFieldsValue({
                ...record,
                name: `${record.first_name} ${record.last_name}`,
                start_datetime: moment.unix(record.start_datetime),
                end_datetime: moment.unix(record.end_datetime),
              });
              setModalVisible(true);
            }}
          />
        ),
      }],
      displayCourse ? courseCol : [],
      !(!displayLesson || !displayCourse) ? lessonCol : [],
      displayStudent ? userCol : [],
      [{
        title: t('status'),
        dataIndex: 'status',
        render: (value) => Helper.displayAttendanceStatus(value),
      },
      {
        title: t('remark'),
        dataIndex: 'remark',
      }]
    );
  }

  const onFinish = async (formObj) => {
    const { attendance_id, remark, status } = formObj;
    setSubmitLoading(true);
    const resp = await apiRoutes.postAttendanceRecord({
      attendance_id,
      remark,
      status
    });
    setSubmitLoading(false);
    if (resp.status === -1) {
      return;
    }
    setModalVisible(false);
    form.resetFields()
    refresh();
  }

  return (
    <>
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={loading}
      >
        <Table
          // title={() => t('course_list')}
          locale={{ emptyText: loading && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setAttendanceTableHeader()}
        />
      </Spin>

      <Modal
        visible={modalVisible}
        title={t('update_attendance_record')}
        onCancel={() => setModalVisible(false)}
        width="80%"
        footer={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="attendance_id" hidden>
            <Input />
          </Form.Item>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item name="user_no" label={t('user_id')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="name" label={t('student_name')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="nickname" label={t('nickname')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="course_code" label={t('course_code')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item name="course_name" label={t('course_name')}>
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="start_datetime" label={t('start_date')}>
                <DatePicker disabled format={defaultDateFormat} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="start_datetime" label={t('start_time')}>
                <TimePicker disabled format={defaultTimeFormat} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="start_datetime" label={t('end_time')}>
                <TimePicker disabled format={defaultTimeFormat} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="status" label={t('status')}>
                <Radio.Group options={Options.attendStatusOptions()} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="remark" label={t('remark')}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={submitLoading}
              type="primary"
              htmlType="submit"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default LessonAttendanceTable;
