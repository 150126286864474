import * as Service from '../Service';

import _ from 'lodash';

export async function getCourseByCourseCode(courseCode) {
  if (_.isNil(courseCode)) return {}
  const url = `/api/admin/course/${courseCode}`;
  const resp = await Service.call('get', url)

  if (resp.status === 1) {
    return resp.data;
  }
  return {};
}

export async function getCourseLessonByCourseCode(courseCode) {
  if (_.isNil(courseCode)) return []
  const url = `/api/admin/course/lesson/${courseCode}`
  const resp = await Service.call('get', url)

  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function putCourse(putObj) {
  const url = `/api/admin/course`
  const resp = await Service.call('put', url, putObj)

  return resp;
}

export async function postCourse(postObj) {
  const url = `/api/admin/course`
  const resp = await Service.call('post', url, postObj)

  return resp;
}

export async function putCourseLesson(putObj) {
  const url = `/api/admin/course/lesson`
  const resp = await Service.call('put', url, putObj)

  return resp;
}

export async function postCourseLesson(postObj) {
  const url = `/api/admin/course/lesson`
  const resp = await Service.call('post', url, postObj)

  return resp;
}

export async function getCourseList() {
  const resp = await Service.call('get', '/api/admin/course');
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function getCourseStudentList(courseCode) {
  const resp = await Service.call('get', `/api/admin/course/student/${courseCode}`);
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}
