import React, { useEffect, useState } from 'react';
import {
  Spin, Row, Col, Form, Select, DatePicker, Button, Statistic
} from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import NavAndSideFrame from '../components/NavAndSideFrame';
import * as Service from '../core/Service'
import OptionList from '../components/OptionList'

const { Option } = Select;

const Home = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    student: [],
    coach: [],
    appliedCourse: [],
  })

  useEffect(() => {
    getDashboardData();
  }, [])

  const getDashboardData = async () => {
    const url = '/api/admin/stats';
    const resp = await Service.call('get', url);
    if (resp.status === 1) {
      setData(resp.data);
    }
  }

  return (
    <NavAndSideFrame title={t('dashboard')} selectedKey="dashboard">
      <Row gutter={8}>
        <Col>
          <Statistic title="學生人數" value={data.student.length} />
        </Col>
        <Col>
          <Statistic title="教練人數" value={data.coach.length} />
        </Col>
        <Col>
          <Statistic title="課堂申請數量" value={data.appliedCourse.length} />
        </Col>
      </Row>
    </NavAndSideFrame>
  )
}


export default Home;
