import * as Service from '../Service';

export async function getSiteMeta() {
  let resp = await Service.call('get', `/api/admin/site_meta`);
  return resp;
}

export async function putSiteMeta(siteMetaID, itemObj) {
  let resp = await Service.call('put', `/api/admin/site_meta/${siteMetaID}`, itemObj);
  return resp;
}
