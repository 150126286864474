import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col, Input, notification, Popconfirm, Select, Spin, Modal, Image
} from 'antd';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import { QrcodeOutlined } from '@ant-design/icons';
import QRCode from "react-qr-code";

const QRcodePreview = (props) => {
  const app = useSelector((state) => state.app);
  const admin = useSelector((state) => state.app.admin);
  const { config } = app;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const { id } = props;

  return (
    <>
      <Tooltip title={t('qrcode')}>
        <Button
          shape="circle"
          style={{ color: '#000000' }}
          icon={<QrcodeOutlined />}
          onClick={() => { setVisible(true) }}
        />
      </Tooltip>

      <Modal
        title={id}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width="350px"
      >
        <QRCode value={id} />
      </Modal>
      {/* <Image
        style={{ marginRight: 8, color: '#000000' }}
        preview={{
          visible,
          src: `${app.config.STATIC_SERVER_URL}/${record.qrcode}`,
          onVisibleChange: (value) => {
            setVisible(value);
          }
        }}
      /> */}
    </>
  )
}
export default QRcodePreview;
