import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Divider, Layout } from 'antd';
import Sidebar from './Sidebar';
import Navbar from './Navbar';

const { Content } = Layout;

export default class NavAndSideFrame extends React.PureComponent {
  render() {
    let {
      title, children, selectedKey, openKey
    } = this.props;
    return (
      <Layout>
        <Navbar {...this.props} />
        <Layout style={{ background: '#fff'}}>
          <Sidebar {...this.props} defaultSelectedKeys={selectedKey} defaultOpenKeys={openKey} />
          <Layout>
            <Content
              style={{
                background: '#fff', margin: 0, minHeight: 280, padding: 24
              }}
            >
              <h3 style={{marginTop: '5px'}}>{title}</h3>
              <Divider />
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    )
  }
}

NavAndSideFrame.defaultProps = {
  title: "Title",
  children: null
}
