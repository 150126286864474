import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BlogTable from '../../components/blog/BlogTable';
import BlogModal from '../../components/blog/BlogModal';
import NavAndSideFrame from '../../components/NavAndSideFrame';

const selectedKey = 'blog';
const openKey = 'blog_management';

const { TabPane } = Tabs;

const BlogList = (props) => {
  const { t } = useTranslation();
  const title = t('blog_list')

  const [init, setInit] = useState(true);
  const [activeTab, setActiveTab] = useState('user');
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          if (init && key !== 'blog') {
            setInit(false);
            setCallRefresh(true);
          }
        }}
      >
        <TabPane tab={t('blog_list')} key="blog">
          <BlogTable
            active={activeTab === 'blog'}
            setModalVisible={setModalVisible}
            setDataObj={setDataObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
      </Tabs>
      <BlogModal
        dataObj={dataObj}
        setDataObj={setDataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default BlogList;