import _ from 'lodash';
import axios from 'axios';
import { ActionCreators } from '../redux/actions';
import { getStore } from '../redux/store/configureStore';
import { notification } from 'antd';

import * as Main from './Main';

const message = {
  put: 'update',
  post: 'create',
  delete: 'delete',
}

export async function call(_method, _endpoint, _data, requireNotification = true) {
  try {
    let method = _.toString(_method).toLowerCase();
    let endpoint = _.toString(_endpoint);
    let data = _.clone(_data) || {};

    let resp = await axios[method](endpoint, method === 'get' ? { params: data } : data);
    let respData = resp.data;
    let {
      status, errorCode, errorMessage, result
    } = respData;

    if (status <= 0) {
      // console.error(`Service.call() Error :: ${errorCode} :: ${errorMessage}`);

      if (requireNotification && !_.isUndefined(message[method])) {
        notification.error({
          message: Main.getErrorCodeMsg(errorCode, errorMessage) || `失敗`,
          duration: 0
        });
      }

      if (errorCode === -101) {
        getStore().dispatch(ActionCreators.setAdmin({}));
        getStore().dispatch(ActionCreators.setAuth(false));
      }
      let errorObj = {
        status,
        errorCode,
        errorMessage
      }
      return errorObj;
    }
    // TODO :: remove result in response
    if (method !== 'get' && requireNotification) {
      notification.success({
        message: `成功`,
        duration: 0
      });
    }

    if (result) {
      return result;
    }
    return respData;
  } catch (err) {
    console.error(err);
  }
  return {
    status: -1
  };
}

export function createURL(action, endpoint, content) {
  content = content || {};
  let url = '';
  url += endpoint;
  if (action.toLowerCase() === 'get') {
    let queryStr = '';
    _.each(content, (val, key) => {
      queryStr += `${key}=${val}&`;
    });
    url += `?${queryStr}`;
  }
  return url;
}

// export async function getUser() {
//   let userData = await call('get', '/api/user');
//   if (userData) {
//     getStore().dispatch(ActionCreators.setAuth(true));
//     getStore().dispatch(ActionCreators.setAdmin(userData));
//   }
//   return userData;
// }

// export async function getCompany() {
//   let companyData = await call('get', '/api/company');
//   if (companyData) {
//     getStore().dispatch(ActionCreators.setCompany(companyData.result));
//   }
//   return companyData;
// }

export async function logout() {
  let result = await call('post', '/api/auth/admin/logout');
  // getStore().dispatch(ActionCreators.setAuth(false));
  // getStore().dispatch(ActionCreators.setAdmin({}));
  return result;
}


export default call;
