import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { UserTable, UserModal } from '../../components/user';

const { TabPane } = Tabs;

const selectedKey = 'user';
const openKey = 'user_management';

const UserList = (props) => {
  const { t } = useTranslation();
  const title = t('user_list')
  const [init, setInit] = useState(true);
  const [activeTab, setActiveTab] = useState('user');
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          if (init && key !== 'user') {
            setInit(false);
            setCallRefresh(true);
          }
        }}
      >
        <TabPane tab={t('user_list')} key="user">
          <UserTable
            active={activeTab === 'user'}
            setModalVisible={setModalVisible}
            setDataObj={setDataObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
      </Tabs>

      <UserModal
        dataObj={dataObj}
        setDataObj={setDataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default UserList;
