import React, { useState, useEffect } from 'react';
import {
  Button, Table, Row, Col, Tooltip, Popconfirm, Spin, Divider, Input, Image, Tag,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import * as Helper from '../../core/helper/RenderDisplay';
import {useFetch} from '../../hooks';
import SearchBar from '../SearchBar';

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

const tableIDName = 'faq_id';

const FaqTable = (props) => {
  const {
    setModalVisible,
    setDataObj,
    callRefresh,
    setCallRefresh,
  } = props;

  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/faq',
    order: { key: ['is_active', 'utime'], value: ['desc', 'desc']},
    callRefresh,
    setCallRefresh
  })

  const { t } = useTranslation();
  const app = useSelector((state) => state.app)

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <Row
              gutter={[10, 0]}
            >
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setModalVisible(true);
                      setDataObj(record)
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={t('delete')}>
                  <Popconfirm
                    title={t('confirm_delete')}
                    placement="right"
                    onConfirm={async () => {
                      await Service.call('delete', `/api/admin/faq/${value}`)
                      setRefresh(true)
                    }}
                    cancelText={t('cancel')}
                    okText={t('ok')}
                  >
                    <Button
                      shape="circle"
                      style={{ color: 'red' }}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('id'),
        dataIndex: 'faq_id',
        sorter: (a, b) => a.faq_id - b.faq_id
      },
      {
        title: t('sorting'),
        dataIndex: 'sorting',
        sorter: (a, b) => a.sorting - b.sorting
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
      {
        title: t('title'),
        dataIndex: 'title',
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: t('content'),
        dataIndex: 'content',
        render: (value, type) => {
          return htmlToReactParser.parse(value)
        },
      },
      {
        title: t('created_time'),
        dataIndex: 'ctime',
        sorter: (a, b) => a.ctime - b.ctime,
        render: (value) => Helper.displayMoment(value)

      },
      {
        title: t('updated_time'),
        dataIndex: 'utime',
        sorter: (a, b) => a.utime - b.utime,
        render: (value) => Helper.displayMoment(value)

      }
    ];
    return columns;
  }

  return (
    <>
      <Row>
        <SearchBar
          fields={[
            'faq_id',
            'title',
            'content'
          ]}
          data={dataList}
          setData={setDataList}
          refresh={refresh}
        />
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
        />

      </Spin>
    </>
  )
}

export default FaqTable;
