import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import moment from 'moment'
import NavAndSideFrame from '../components/NavAndSideFrame';
import SiteMetaTable from '../components/SiteMetaTable';

const selectedKey = "site_meta";
const openKey = "app_config";

const SiteMetaList = (props) => {
  const { t } = useTranslation()

  return (
    <NavAndSideFrame title={t('site_meta_list')} selectedKey={selectedKey} openKey={openKey}>
      <SiteMetaTable />
    </NavAndSideFrame>
  )
}


export default SiteMetaList;
