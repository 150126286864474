import React, { useState, useEffect } from 'react';
import {
    Button, Divider, Table, Tag, Tooltip, Row, Col, Input, notification, Popconfirm, Select, Spin, Modal, Image
  } from 'antd';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

import {
  PlusSquareOutlined,
  } from '@ant-design/icons';

const ImagePreview = (props) => {
    const app = useSelector((state) => state.app);
    const { config } = app;
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const {record} = props;

    return (
    <>
    <Button shape="circle" style={{ marginRight: 8, color: '#000000' }}
    icon={<PlusSquareOutlined />}
    onClick={() => {setVisible(true)}}>
    </Button>
    <Image 
    style={{ marginRight: 8, color: '#000000' }}
    preview={{  
    visible,
    src: `${app.config.STATIC_SERVER_URL}/${record.image_attachment}`,
    onVisibleChange: value =>{
      setVisible(value);
    }
    }}    
    />
    </>
    )
}
export default ImagePreview;