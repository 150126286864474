import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Input, Row, Col,
  Select, DatePicker, Spin, Descriptions
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  SearchOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import { useFetch } from '../../hooks';
import * as Helper from '../../core/helper/RenderDisplay'
import LessonAttendanceTable from './LessonAttendanceTable';
import apiRoutes from '../../core/api';

const { Option } = Select

const StudentAttendanceTable = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [searchStudentID, setSearchStudentID] = useState('');
  const [studentList, setStudentList] = useState([]);
  const [dataList, setDataList] = useState([]);

  const [user, setUser] = useState({});

  const getData = async () => {
    setLoading(true);
    const resp = await apiRoutes.getAttendanceListByStudent(searchStudentID);
    const { attendance, users } = resp;
    if (users.length === 1) {
      setUser(_.first(users));
    }
    setStudentList(users);
    setDataList(attendance);
    setLoading(false);
  }

  const setStudentTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: "user_id",
        render: (value, record) => {
          return (
            <Row gutter={12}>
              <Col>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<SearchOutlined />}
                    onClick={() => {
                      setUser(record);
                    }}
                  />
              </Col>
              <Col>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('user_id'),
        dataIndex: `user_no`,
      },
      {
        title: t('name'),
        dataIndex: `first_name`,
        render: (val, record) => {
          return `${record?.first_name ?? ''} ${record?.last_name ?? ''}`
        }
      },
      {
        title: t('age'),
        dataIndex: `age`,
      },
      {
        title: t('mobile'),
        dataIndex: 'phone',
      },
      {
        title: t('email'),
        dataIndex: 'email',
      },
      {
        title: t('qrcode'),
        dataIndex: 'ctime',
        render: (value) => <QrcodeOutlined />,
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
      },
    ];
    return columns
  }

  const refresh = () => {
    setUser({});
    getData();
  }

  return (
    <>
      <Row gutter={[24, 24]} align="bottom">
        <Col span={6}>
          <Input
            value={searchStudentID}
            onChange={(event) => setSearchStudentID(event.target.value)}
            placeholder={t('search_student')}
          />
        </Col>
        <Col>
          <Button
            disabled={loading}
            onClick={refresh}
            type="primary"
          >
            {t('submit')}
          </Button>
        </Col>
      </Row>
      <Divider />
      {_.toLength(studentList) === 1 || !_.isEmpty(user) ? (
        <>
          <Descriptions layout="vertical" labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item label={t('student_name')} span={2}>
              {user.first_name} {user.last_name}
            </Descriptions.Item>
            <Descriptions.Item>
              {user.nickname}
            </Descriptions.Item>
            <Descriptions.Item label={t('mobile')}>
              {user.mobile}
            </Descriptions.Item>
            <Descriptions.Item label={t('email')} span={2}>
              {user.email}
            </Descriptions.Item>
          </Descriptions>
          <Divider />
          <LessonAttendanceTable
            dataList={_.filter(dataList, {user_id: user.user_id})}
            displayStudent={false}
            refresh={getData}
          />
        </>
      ) : (
        <>
          <Table
            title={() => t('user_list')}
            locale={{ emptyText: loading && (<div style={{ height: 166 }} />) }}
            rowKey="user_id"
            scroll={{ x: 'max-content' }}
            dataSource={studentList}
            columns={setStudentTableHeader()}
          />
        </>
      )}
    </>
  )
}

export default StudentAttendanceTable;
