import * as Service from '../Service';

export async function getAreaList() {
  const url = '/api/admin/location';
  const resp = await Service.call('get', url);
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
};
