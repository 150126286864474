import React, { useMemo, useState } from 'react';
import {
  Layout, Row, Col, Select, Button
} from 'antd';
import 'antd/dist/antd.css';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash';
import { setLanguage } from '../redux/actions/common';
import AdminModal from "./admin/AdminModal"

const { Header } = Layout;
const { Option } = Select;

const Navbar = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.app.admin);
  const config = useSelector((state) => state.app.config);

  const setLocalStorage = (value) => {
    if (!_.isUndefined(typeof localStorage)) {
      localStorage.setItem('language', value);
    }
  }

  const LanguageSelect = () => {
    let LanguageList = {
      en: "English",
      zh_hant: "中文（繁體）"
    }

    return (
      <Select
        value={i18n.language}
        style={{ width: 130, color: '#000' }}
        onChange={(value) => {
          i18n.changeLanguage(value)
          setLocalStorage(value)
          dispatch(setLanguage(value))
        }}
      >
        {_.map(LanguageList, (val, key) => {
          return (
            <Option key={key} value={key}>
              {val}
            </Option>
          );
        })}
      </Select>
    );
  };

  const profileColor = () => {
    let profileStyle = {}
    switch (admin.type) {
      case 'SuperUser':
        profileStyle = {
          color: '#FFF',
          backgroundColor: 'red',
          borderColor: 'red'
        }
        break;
      case 'NormalAdmin':
        profileStyle = {
          color: '#FFF',
          backgroundColor: 'blue',
          borderColor: 'blue'
        }
        break;
      case 'CarParkAdmin':
        profileStyle = {
          color: '#FFF',
          backgroundColor: 'green',
          borderColor: 'green'
        }
        break;
      case 'PIDAdmin':
        profileStyle = {
          color: '#FFF',
          backgroundColor: 'orange',
          borderColor: 'orange'
        }
        break;
      default:
        profileStyle = {
          color: '#8c8c8c'
        }
        break;
    }
    return profileStyle
  }

  const headerConfig = useMemo(() => {
    switch (config.STAGE) {
      case 'development':
      case 'testing': {
        return {
          background: 'linear-gradient(to right,#006ABF 0,#054f8a 100%)',
          image_path: "/assets/logo.png"
        }
      }
      case 'staging': {
        return {
          background: '#FF8C00',
          image_path: "/assets/logo_white.png"
        }
      }
      case 'production': {
        return {
          background: '#005446',
          image_path: "/assets/logo_white.png"
        }
      }
      default: {
        return {
          background: 'linear-gradient(to right,#006ABF 0,#054f8a 100%)',
          image_path: "/assets/logo.png"
        }
      }
    }
  }, [])

  return (
    <Header
      className="header"
      style={{
        background: headerConfig.background, height: 70, position: 'relative', display: 'flex', justifyContent: 'center'
      }}
    >
      <AdminModal
        profile
        dataObj={admin}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col xs={0} lg={8}>
          <span style={{color: "white", fontSize: "22px", fontWeight: 600}}>
            {t('admin_management_system')}
          </span>
        </Col>
        <Col style={{fontSize: 24, color: 'white'}}>
          強生體育會有限公司
        </Col>
        {/* <Col xs={0} lg={8}>
          <div style={{ height: 70 }}>
            <img
              alt="banner-logo"
              src={headerConfig.image_path}
              style={{
                height: '70%',
                width: '100%',
                objectFit: 'contain'
              }}
            />
          </div>
        </Col> */}
        <Col xs={24} lg={8}>
          <Row justify="end" align="middle" gutter={[24, 0]}>
            <Col>
              <Button
                onClick={() => {
                  setModalVisible(true);
                }}
                style={profileColor()}
                shape="circle"
                icon={!_.isEmpty(admin.name) ? admin.name.charAt(0) : <UserOutlined />}
                size="large"
              />
            </Col>
            <Col>
              <div style={{ textAlign: 'center' }}>{LanguageSelect()}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}

const styles = {
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%'
  }
}

export default Navbar;
