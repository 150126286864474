import React, { useState } from 'react';

import {
  PlusOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import NotificationModal from '../../components/notification/NotificationModal';
import NotificationTable from '../../components/notification/NotificationTable';
import { Button, Divider } from 'antd';

const selectedKey = 'notice';
const openKey = 'notice_management';

const NoticeList = (props) => {
  const { t } = useTranslation();
  const title = t('notification');

  const [callRefresh, setCallRefresh] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {t('add_notification')}
      </Button>
      <Divider />
      <NotificationTable
        callRefresh={callRefresh}
        setCallRefresh={setCallRefresh}
      />

      <NotificationModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default NoticeList;
