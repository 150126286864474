import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tooltip, Row, Image,
  notification, Spin, Modal
} from 'antd';
import {
  EditOutlined,
  StopOutlined,
  CheckOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useFetch } from '../../hooks';
import SearchBar from '../SearchBar';
import * as Helper from '../../core/helper/RenderDisplay'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import { useSelector } from 'react-redux';
import ImagePreview from '../ImagePreview';

const tableIDName = "blog_id";

const BlogTable = (props) => {
  const app = useSelector((state) => state.app);
  const [visible, setVisible] = useState(false);
  const {config} = app;
  const { t } = useTranslation();

  const {
    setModalVisible, setDataObj, callRefresh, setCallRefresh
  } = props;

  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/blog',
    order: { key: ['is_active', 'utime'], value: ['desc', 'desc']},
    callRefresh,
    setCallRefresh
  })

  const deleteBlog = async (blog_id) => {
    setRefresh(true);
    let endpoint = `/api/admin/blog/${blog_id}`
    
    const resp = await Service.call('delete', endpoint)
    setRefresh(false);
    if (resp.status < 1) {
      return;
    }
    setCallRefresh(true);
  };

  const recoverBlog = async (blog_id) => {
    setRefresh(true);
    let endpoint = `/api/admin/blog/${blog_id}`
    
    const resp = await Service.call('post', endpoint)
    setRefresh(false);
    if (resp.status < 1) {
      return;
    }
    setCallRefresh(true);
  };

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          let status = (record.status === 'Active');
          let color = '#000000';
          let icon = '';
          let wordings = '';
          if (status) {
            color = 'red'
            icon = <StopOutlined />;
            wordings = t('deactivate');
          } else {
            color = 'green'
            icon = <CheckOutlined />;
            wordings = t('activate');
          }
          return (
            <span>
              <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setDataObj(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip>
              <Tooltip title={t('stop_blog')}>
                {record.is_active === 1 && (
                  <Button
                    shape="circle"
                    style={{ marginRight: 8, color: '#000000' }}
                    icon={<StopOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: '確認改變文章狀態嗎？',
                        onOk: () => deleteBlog(value),
                      })
                    }}
                  />
                )}
              </Tooltip>
              <Tooltip title={t('recover_blog')}>
                {record.is_active === 0 && (
                  <Button
                    shape="circle"
                    style={{ marginRight: 8, color: '#000000' }}
                    icon={<StopOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: '確認改變文章狀態嗎？',
                        onOk: () => recoverBlog(value),
                      })
                    }}
                  />
                )}
              </Tooltip>
              {/* <Tooltip title={t('image')}>
                {record.iamge_attachment !== 0 &&
                  <Button
                    shape="circle"
                    style={{ marginRight: 8, color: '#000000' }}
                    icon={<PlusSquareOutlined />}
                    onClick={() => setVisible(true)}
                    // onClick={() => { `${config.STATIC_SERVER_URL}/${value.iamge_attachment}`}}
                    />
                  }
              </Tooltip> */}
              {/* <Button title={t('image')} shape="circle" style={{ marginRight: 8, color: '#000000' }}
              icon={<PlusSquareOutlined />}
              onClick={() => setVisible(true)}>
                </Button>
                <Image
                style={{ marginRight: 8, color: '#000000' }}
                  preview={{
                    visible,
                    src: `${config.STATIC_SERVER_URL}/${record.image_attachment}`,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                /> */}
              <Tooltip title={t('image')}>
                <ImagePreview record={record} />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
      },
      // {
      //   title: t('blog_id'),
      //   dataIndex: `blog_id`,
      //   sorter: (a, b) => a.blog_id - b.blog_id,
      // },
      // {
      //   title: t('level'),
      //   dataIndex: 'privilege_level',
      //   render: (value) => {
      //     if (value === 1) return 'Super Admin';
      //     if (value === 2) return 'Normal Admin';
      //   }
      // },
      {
        title: t('blog_title'),
        dataIndex: `blog_title`,
      },
      {
        title: t('blog_content'),
        dataIndex: 'blog_content',
        // sorter: (a, b) => a.phone.localeCompare(b.phone)
      },
      // {
      //   title: t('image'),
      //   dataIndex: 'image_attachment',
      // },
      {
        title: t('updated_time'),
        dataIndex: 'utime',
        render: (value) => Helper.displayMoment(value),
      },
      {
        title: t('created_time'),
        dataIndex: 'ctime',
        render: (value) => Helper.displayMoment(value),
      },
      // {
      //   title: t('published_date'),
      //   dataIndex: 'published_date',
      //   render: (value) => Helper.displayMoment(value),
      // },
    ];
    return columns
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setDataObj({});
          setModalVisible(true);
        }}
      >
        {`${t('create')} ${t('blog')}`}
      </Button>
      <Divider />
      <Row gutter={[20, 20]}>
        <SearchBar
          fields={[
            'blog_title',
            'blog_content',
          ]}
          data={dataList}
          setData={setDataList}
          refresh={refresh}
        />
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
        />
      </Spin>
    </>
  )
}

export default BlogTable
