import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import CourseInformation from '../../components/course/CourseInformation';
import CourseLessonTable from '../../components/course/CourseLessonTable';
import CourseStudentList from '../../components/course/CourseStudentList';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import apiRoutes from '../../core/api';

const selectedKey = 'course';
const openKey = 'course_management';

const { TabPane } = Tabs;

const CourseDetail = (props) => {
  const { t } = useTranslation();
  const title = t('course_information');

  const location = useLocation();
  const params = useParams();
  const {courseCode} = location.state || params;

  const [activeTab, setActiveTab] = useState('course');

  const [callRefresh, setCallRefresh] = useState(true);
  const [locationList, setLocationList] = useState([]);
  const [coachList, setCoachList] = useState([]);

  const [course, setCourse] = useState({});

  console.log("courseCode >>>", courseCode, location.state, params)

  useEffect(() => {
    if (callRefresh) {
      getAreaList();
      getCoachList();
      setCallRefresh(false);
    }
  }, [callRefresh]);

  const getAreaList = async () => {
    const list = await apiRoutes.getAreaList();
    setLocationList(list);
  }

  const getCoachList = async () => {
    const list = await apiRoutes.getCoachList();
    setCoachList(list);
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          setCallRefresh(true);
        }}
      >
        <TabPane tab={t('course_information')} key="course">
          <CourseInformation
            course={course}
            setCourse={setCourse}
            courseCode={courseCode}
            coachList={coachList}
            locationList={locationList}
            callRefresh={callRefresh && activeTab === 'course'}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
        <TabPane tab={t('lesson_list')} key="lesson">
          <CourseLessonTable
            course={course}
            courseCode={courseCode}
            coachList={coachList}
            locationList={locationList}
            callRefresh={callRefresh && activeTab === 'lesson'}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
        <TabPane tab={t('user_list')} key="student">
          <CourseStudentList
            course={course}
            callRefresh={callRefresh && activeTab === 'course'}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
      </Tabs>

    </NavAndSideFrame>
  )
}

export default CourseDetail;
