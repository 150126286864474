import React, { useEffect } from 'react';
import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import {
  Button, Col, Input, Layout, Row, Select, Form, notification
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAuth, setAdmin } from '../redux/actions/common'
import apiRoutes from '../core/api';

const { Option } = Select;

const Login = () => {
  const app = useSelector((state) => state.app)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (!_.isEmpty(app.admin) && app.admin.uid > 0) {
      history.push('/admin/home')
    }
  }, [])

  const onFinish = async (formData) => {
    let { phonePrefix, phone, password } = formData;
    let resp = await apiRoutes.login({
      phone: _.join([phonePrefix, phone], '-'), password
    })
    if (resp.status < 1) {
      notification.error({
        message: `${t('login')} ${t('fail')}`,
      });
      return dispatch(setAuth(false));
    }
    if (_.isEmpty(resp.data)) return dispatch(setAuth(false));

    dispatch(setAdmin(resp.data));
    dispatch(setAuth(true));
    notification.success({
      message: `${t('login')} ${t('success')}`,
    });
    history.push('/admin/home')
  }

  const prefixSelector = (
    <Form.Item name="phonePrefix" noStyle initialValue="+852">
      <Select disabled bordered={false} style={{ width: '100%', background: 'none' }}>
        <Option value="+852">+852</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Layout>
      <Layout.Content style={{ padding: '50px', backgroundColor: 'white' }}>
        <Row style={{ minHeight: 250 }} align="middle" justify="center">
          <Col xs={20} sm={16} md={14} lg={10} xl={8}>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <div>
                <img
                  alt=""
                  src="/assets/logo.png"
                  style={{
                    width: '100%', maxWidth: '300px', objectFit: 'contain'
                  }}
                />
              </div>
              <div style={{ margin: '20px 0px' }}>
                <h2>KGC Swimming Membership</h2>
                <h3>{t('admin_panel')}</h3>
              </div>
            </div>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: '15px' }}>
          <Col
            xs={22}
            sm={16}
            md={14}
            lg={10}
            xl={8}
          >
            <div className="" style={{ display: 'flex', justifyContent: 'center' }}>
              <Form
                className="login-form col-12 mt-2"
                style={{ width: '100%' }}
                form={form}
                onFinish={onFinish}
              >
                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <Input
                    style={styles.formInput}
                    size="large"
                    prefix={(
                      <Row align="middle">
                        <Col>
                          <PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                        </Col>
                        <Col>
                          {prefixSelector}
                        </Col>
                      </Row>
                    )}
                    placeholder={t('phone')}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: t('input_required') }]}
                >
                  <Input.Password
                    style={styles.formInput}
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={t('password')}
                  />
                </Form.Item>
                <Button
                  style={styles.button}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  <span
                    style={styles.buttonText}
                  >
                    {t('login')}
                  </span>
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}

const styles = {
  button: {
    width: '100%',
    borderRadius: 10,
    backgroundColor: '#005446',
    borderColor: 'transparent',
    height: 54
  },
  buttonText: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: '28px',
    color: '#fff'
  },
  formInput: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: '28px',
    backgroundColor: 'transparent',
    border: '1px solid #CDCDCD',
    color: '#005446',
    width: '100%',
    minHeight: 64,
    borderRadius: 10,
  }
}

export default Login;
