import * as Service from '../Service';

import _ from 'lodash';

export async function getAttendanceListByCourseCode(courseCode) {
  const url = `/api/admin/attendance/course/${courseCode}`;
  const resp = await Service.call('get', url)

  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function getAttendanceListByStudent(studentID) {
  const url = `/api/admin/attendance/student/${studentID}`;
  const resp = await Service.call('get', url)

  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function getAttendanceListByLesson(lessonID) {
  const url = `/api/admin/attendance/lesson/${lessonID}`;
  const resp = await Service.call('get', url)

  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function putAttendanceRecord(updateObj) {
  const url = `/api/admin/attendance/lesson`;
  const resp = await Service.call('put', url, updateObj)

  return resp;
}

export async function postAttendanceRecord(updateObj) {
  const url = `/api/admin/attendance/lesson`;
  const resp = await Service.call('post', url, updateObj)

  return resp;
}
