import React, { useState, useEffect } from "react";
import {
  Select,
  Modal,
  Tabs,
} from "antd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import CourseForm from "./CourseForm";

const CourseModal = (props) => {
  const {
    dataObj,
    setDataObj,
    modalVisible,
    setModalVisible,
  } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      centered
      title={`${action === 'post' ? t('create') : t('edit')} ${t("course")}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setDataObj({})
        setModalVisible(false);
      }}
    >
      <CourseForm {...props} action={action} />
    </Modal>
  );
};

export default CourseModal;
