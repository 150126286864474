import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  notification,
  Tooltip,
  Tabs,
  Row,
  Col,
  Radio,
  Table,
  InputNumber,
  DatePicker,
} from "antd";
import { useTranslation } from "react-i18next";
import { EditOutlined } from '@ant-design/icons';
import Joi from 'joi';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TextArea from "antd/lib/input/TextArea";
import { setAdmin, setAuth } from '../../redux/actions/common'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import * as UserApi from "../../core/api/user";
import * as AttendanceApi from "../../core/api/attendance";
import * as Helper from '../../core/helper/RenderDisplay';
import * as FormOptions from '../../core/helper/FormOptions';
import moment from "moment";

const { Option } = Select;
const { TabPane } = Tabs;

const UserModal = (props) => {
  const {
    dataObj,
    setDataObj,
    modalVisible,
    setModalVisible,
    setCallRefresh,
    profile
  } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      centered
      destroyOnClose
      title={`${action === 'post' ? t('create') : t('edit')} ${!profile ? t("user") : t('user_profile')}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setDataObj({})
        setModalVisible(false);
      }}
    >
      {action === 'post'
        ? <UserProfileForm {...props} action={action} />
        : (
          <Tabs type="card" defaultActiveKey="user-info-form">
            <TabPane tab={t('user_profile')} key="user-info-form">
              <UserProfileForm {...props} action={action} />
            </TabPane>
            {!_.isEmpty(dataObj) && (
              <TabPane tab={t('change_password')} key="change-pw-form">
                <ChangePasswordForm
                  profile={profile}
                  user_id={dataObj.user_id}
                  setModalVisible={setModalVisible}
                  setCallRefresh={setCallRefresh}
                />
              </TabPane>
            )}

            {!_.isEmpty(dataObj) && (
              <TabPane tab={t('attendance')} key="attendance-table">
                <AttendanceTable
                  user_id={dataObj.user_id}
                  setModalVisible={setModalVisible}
                  setCallRefresh={setCallRefresh}
                />
              </TabPane>
            )}
          </Tabs>
        )}
    </Modal>
  );
};

const UserProfileForm = (props) => {
  const {
    profile, dataObj, action, userType, setModalVisible, setCallRefresh
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  const admin = useSelector((state) => state.app.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue(dataObj);
    if (dataObj.phone && _.includes(dataObj.phone, '-')) {
      let [phone_prefix, phone] = _.split(dataObj.phone, '-')
      form.setFieldsValue({ ...dataObj, phone, phonePrefix: phone_prefix });
    }
  }, []);

  const typeSelector = () => {
    let options = []
    _.each(userType, (value, key) => {
      if (value === 'User') {
        return
      }
      options.push(<Option value={value} key={key}>{value}</Option>);
    })
    return options;
  }

  const onFinish = async (values) => {
    setSubmitLoading(true);
    let { phonePrefix, phone } = values;
    const itemObj = {
      ...values,
      phone: phone ? _.join([phonePrefix, phone], '-') : null,
      regular_user_id: _.toInteger(values.regular_user_id)
    };
    let endpoint = ''
    if (action === 'post') {
      endpoint = '/api/admin/user'
    } else {
      endpoint = `/api/admin/user/${dataObj.user_id}`
    }

    const resp = await Service.call(action, endpoint, itemObj)
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    if (!profile) {
      setCallRefresh(true);
    }
    const userData = await Service.call('get', '/api/admin')
    dispatch(setAdmin(userData.data));
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="user_profile_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={t('mobile')}
            name="phone"
            validateFirst
            rules={[{ required: true, message: t('input_required') },
              () => ({
                validator(rule, value) {
                  if (_.toInteger(value) > 0 || _.isEmpty(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Invalid phone number'));
                }
              })]}
          >
            <Input addonBefore={FormOptions.prefixSelector()} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('email')}
            name="email"
            rules={[{ type: 'email', message: 'Incorrect email format.' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('first_name')}
            name="first_name"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('last_name')}
            name="last_name"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('address')}
            name="address"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('age')}
            name="age"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('gender')}
            name="gender"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue="M"
          >
            <Radio.Group
              options={FormOptions.genderOptions()}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('status')}
            name="is_active"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.isActiveOptions()}
            />
          </Form.Item>
        </Col>
      </Row>

      {action === 'post' && (
        <>
          <Form.Item
            name="password"
            label={t('password')}
            validateFirst
            rules={[
              {
                required: true,
                message: t('input_required'),
              },
              () => ({
                validator(_, value) {
                  const schema = Joi.string().alphanum()
                  const validateResult = schema.validate(value)
                  if (validateResult.error) {
                    return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
                  }
                  return Promise.resolve();
                },
              }),
              {
                min: 8,
                max: 10
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label={t('confirm_password')}
            dependencies={['password']}
            validateFirst
            hasFeedback
            rules={[
              {
                required: true,
                message: t('input_required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('password_not_match')));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </>
      )}
      {dataObj.user_id !== admin.user_id && !profile && admin.type === 'SuperUser' && dataObj.type !== 'User' && (
        <Form.Item
          label={t('type')}
          name="type"
          rules={[{ required: true, message: t('input_required') }]}
        >
          <Select>
            {typeSelector()}
          </Select>
        </Form.Item>
      )}
      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const ChangePasswordForm = (props) => {
  const {
    profile, user_id, setModalVisible, setCallRefresh
  } = props;
  const history = useHistory()
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [submitLoading, setSubmitLoading] = useState(false);
  const admin = useSelector((state) => state.app.admin)

  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (logout) return history.push('/admin/login')
  }, [logout])


  const onFinish = async (values) => {
    setSubmitLoading(true)
    const itemObj = values;
    let endpoint = `/api/admin/user/change_password/${user_id}`
    if (profile) {
      endpoint = '/api/admin/user/profile/change_password'
    }
    let resp = await Service.call('put', endpoint, itemObj);
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false)
    if (!profile) {
      setCallRefresh(true)
    } else {
      dispatch(setAdmin({}))
      dispatch(setAuth(false))
      setLogout(true)
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="change_pw_form"
      onFinish={onFinish}
    >
      {profile && (
        <Form.Item
          name="old_password"
          label={t('old_password')}
          validateFirst
          rules={[
            {
              required: true,
              message: t('input_required'),
            },
            () => ({
              validator(_, value) {
                const schema = Joi.string().alphanum()
                const validateResult = schema.validate(value)
                if (validateResult.error) {
                  return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
                }
                return Promise.resolve();
              },
            }),
            {
              min: 8,
              max: 10
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      )}

      <Form.Item
        name="new_password"
        label={t('password')}
        validateFirst
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          () => ({
            validator(_, value) {
              const schema = Joi.string().alphanum()
              const validateResult = schema.validate(value)
              if (validateResult.error) {
                return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
              }
              return Promise.resolve();
            },
          }),
          {
            min: 8,
            max: 10
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label={t('confirm_password')}
        dependencies={['password']}
        validateFirst
        hasFeedback
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('password_not_match')));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          loading={submitLoading}
          disabled={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const AttendanceTable = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { user_id } = props;

  const [loading, setLoading] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [dataList, setDataList] = useState([]);

  const [editModalVisible, setEditModalVisible] = useState(false);

  useEffect(() => {
    getCourse();
  }, [user_id]);

  useEffect(() => {
    getCourseLesson();
  }, [selectedCourse, user_id])

  const getCourse = async () => {
    setLoading(true);
    const resp = await UserApi.getUserAppliedCourse(user_id);
    setCourses(resp)
    setLoading(false);
  }

  const getCourseLesson = async () => {
    setLoading(true);
    if (_.isNull(selectedCourse)) return;
    const resp = await UserApi.getUserAppliedCourseLesson(user_id, selectedCourse);
    const result = [];
    const attendanceMapKey = _.groupBy(resp.attendance, 'lesson_id');
    _.map(resp.lesson, (lesson) => {
      const attendanceRec = _.first(attendanceMapKey[lesson.lesson_id]);
      if (_.isUndefined(attendanceRec) || _.isEmpty(attendanceRec)) {
        result.push({
          ...lesson,
          attendance_id: 0,
        });
        return;
      }
      result.push({
        ...lesson,
        ...attendanceRec
      });
    })
    setDataList(result)
    setLoading(false);
  }

  const setTableHeader = () => {
    let list = [];

    list.push({
      title: t('operation'),
      dataIndex: 'attendance_id',
      render: (value, record) => (
        <Tooltip title={t('edit')}>
          <Button
            shape="circle"
            style={{ color: '#000000' }}
            icon={<EditOutlined />}
            onClick={() => {
              const formObj = _.clone(record);
              formObj.date = moment.unix(record.start_datetime);
              form.setFieldsValue(formObj);
              setEditModalVisible(true);
            }}
          />
        </Tooltip>
      )
    })
    list.push({
      title: t('date'),
      dataIndex: 'start_datetime',
      render: (value) => Helper.displayDate(value),
    })
    list.push({
      title: t('start_time'),
      dataIndex: 'start_datetime',
      render: (value) => Helper.displayTime(value),
    })
    list.push({
      title: t('end_time'),
      dataIndex: 'end_datetime',
      render: (value) => Helper.displayTime(value),
    })
    list.push({
      title: t('status'),
      dataIndex: 'status',
      render: (value) => Helper.displayAttendanceStatus(value),
    })
    list.push({
      title: t('remark'),
      dataIndex: 'remark',
    })

    return list;
  }

  const onFinish = async (value) => {
    let dataObj = _.clone(value);
    delete dataObj.date;
    let apiFunction;
    if (value.attendance_id === 0) {
      apiFunction = AttendanceApi.putAttendanceRecord;
      delete dataObj.attendance_id;
      dataObj.user_id = user_id;
      dataObj.course_id = selectedCourse;
    } else {
      apiFunction = AttendanceApi.postAttendanceRecord;
    }
    const resp = await apiFunction(dataObj);

    if (resp.status === 1) {
      setEditModalVisible(false);
      getCourseLesson();
    }
  }

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Select
          loading={loading}
          value={selectedCourse}
          placeholder={t('please_select')}
          style={{ width: '50%' }}
          onChange={setSelectedCourse}
        >
          {_.map(courses, (course) => (
            <Option value={course.course_id} key={course.course_code}>
              {course.course_code}
              -
              {course.course_name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={24}>
        <Table
          loading={loading}
          columns={setTableHeader()}
          dataSource={dataList}
        />
      </Col>
      <Modal visible={editModalVisible} onCancel={() => setEditModalVisible(false)} footer={false}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="attendance_id" hidden />
          <Form.Item name="lesson_id" hidden />
          <Form.Item name="date" label={t('date')}>
            <DatePicker disabled />
          </Form.Item>
          <Form.Item name="status" label={t('status')}>
            <Select options={FormOptions.attendStatusOptions()} />
          </Form.Item>
          <Form.Item name="remark" label={t('remark')}>
            <TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {t('submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  )
}


export default UserModal;
