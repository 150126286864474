import React, { useEffect, useState } from 'react';
import {
  Upload, message, Button, notification
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

function FormUploadFile(props) {
  const {
    type,
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    fileList,
    disabled,
    buttonStyle,
    acceptMimeTypes = ['image/png', 'image/jpeg'],
    ignoreMimeTypes = false,
  } = props;

  let DisplayContent;

  const { t } = useTranslation();

  const uploadProps = {
    name: 'file',
    // multiple: true,
    action: '/api/admin/media',
    data: {scope: 'public'},
    beforeUpload: (file) => {
      if (ignoreMimeTypes) {
        message.success(t('uploading'));
        return true;
      }
      if (!_.includes(acceptMimeTypes, file.type)) {
        notification.error({ message: t('file_type_is_not_accepted') });
        return false
      }
      message.success(t('uploading'));
      return true;
    },
    onError(err) {
      console.error(err)
    }
  };

  const beforeUpload = (file) => {
    const sizePerMB = (file.size / 1024 / 1024) >= 2;

    if (sizePerMB) {
      return notification.error({ message: t('file_size_exceed')});
    }
  }


  if (type === 'one') {
    DisplayContent = (
      <UploadOne
        beforeUpload={beforeUpload}
        uploadProps={uploadProps}
        fileList={fileList}
        imageURL={imageURL}
        data={data}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        disabled={disabled}
      />
    );
  } else if (type === 'button') {
    DisplayContent = (
      <UploadButton
        beforeUpload={beforeUpload}
        uploadProps={uploadProps}
        fileList={fileList}
        imageURL={imageURL}
        data={data}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        buttonStyle={buttonStyle}
        acceptMimeTypes={acceptMimeTypes}
        ignoreMimeTypes
      />
    );
  } else {
    DisplayContent = (
      <UploadWidget
        beforeUpload={beforeUpload}
        uploadProps={uploadProps}
        imageURL={imageURL}
        data={data}
        onChange={onChange}
        onPreview={onPreview}
        onRemove={onRemove}
        disabled={disabled}
      />
    );
  }

  return DisplayContent;
}

function UploadButton(props) {
  const {
    data,
    onChange,
    uploadProps,
    buttonStyle = {},
  } = props;

  let {
    buttonType,
    buttonIcon,
    buttonSize,
    buttonText,
    buttonClassName,
    ...remainerButtonStyle
  } = buttonStyle;

  return (
    <>
      <Upload
        {...uploadProps}
        data={data}
        showUploadList={false}
        onChange={onChange}
        listType="text"
      >
        <Button
          type={buttonType}
          icon={buttonIcon}
          shape="square"
          size={buttonSize}
          style={{
            ...remainerButtonStyle
          }}
          className={buttonClassName}
        >
          {buttonText}
        </Button>
      </Upload>
    </>
  );
}

function UploadOne(props) {
  const { t } = useTranslation();
  const {
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    beforeUpload,
    uploadProps,
    disabled,
  } = props;

  const [image, setImage] = useState('');

  useEffect(() => {
    setImage(imageURL)
  }, [imageURL])
  return (
    <Dragger
      {...uploadProps}
      data={data}
      showUploadList={false}
      onChange={onChange}
      listType="text"
      beforeUpload={beforeUpload}
      onPreview={onPreview}
      onRemove={onRemove}
      disabled={disabled}
    >
      {
        image
          ? (
            <div>
              <img src={image} style={{ maxWidth: '100%', maxHeight: 500 }} alt="" />
            </div>
          ) : (
            <div>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('click_or_drag_the_picture_to_upload')}</p>
              <p className="ant-upload-hint">
                {t('only_jpg_or_png_can_be_uploaded')}
              </p>
            </div>
          )
      }
    </Dragger>
  );
}

function UploadWidget(props) {
  const { t } = useTranslation();
  const {
    imageURL,
    data,
    onChange,
    onPreview,
    onRemove,
    uploadProps,
    disabled,
    beforeUpload
  } = props;

  useEffect(() => {}, [imageURL])

  return (
    <Dragger
      {...uploadProps}
      showUploadList={false}
      data={data}
      onChange={onChange}
      listType="picture"
      beforeUpload={beforeUpload}
      onPreview={onPreview}
      onRemove={onRemove}
      disabled={disabled}
    >
      {
        imageURL
          ? (
            <div>
              <img src={imageURL} style={{ maxWidth: '100%', maxHeight: 400 }} alt="" />
            </div>
          ) : (
            <div>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t('click_or_drag_the_picture_to_upload')}</p>
              <p className="ant-upload-hint">
                {t('only_jpg_or_png_can_be_uploaded')}
              </p>
            </div>
          )
      }
    </Dragger>
  );
}

export default FormUploadFile;
