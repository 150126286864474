import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin, Input, Modal
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  RocketOutlined,
  QrcodeOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import * as Helper from '../../core/helper/RenderDisplay';
import apiRoutes from '../../core/api';
import LessonAttendanceTable from './LessonAttendanceTable';
const { Option } = Select

const tableIDName = "course_id";
const subTableIDName = "lesson_id";

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';

const CourseAttendanceTable = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [searchCourseCode, setSearchCourseCode] = useState('');
  const [dataList, setDataList] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedLesson, setSelectedLesson] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);

  const getData = async () => {
    setLoading(true);
    const resp = await apiRoutes.getAttendanceListByCourseCode(searchCourseCode);
    setDataList(resp);
    if (resp.length === 1) {
      setSelectedCourse(_.first(resp));
    }
    setLoading(false);
  }

  const setCourseTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: `course_id`,
        render: (value, record) => (
          <Button
            shape="circle"
            style={{ color: '#000000' }}
            icon={<SearchOutlined />}
            onClick={() => {
              setSelectedCourse(record)
            }}
          />
        ),
      },
      {
        title: t('course_code'),
        dataIndex: `course_code`,
        sorter: (a, b) => a.user_no.localeCompare(b.user_no),
      },
      {
        title: t('start_date'),
        dataIndex: `lesson`,
        render: (value) => {
          const first_lesson = _.minBy(value, 'start_datetime');
          if (_.isEmpty(first_lesson)) return "-";
          return Helper.displayDate(first_lesson.start_datetime);
        },
      },
      {
        title: t('end_date'),
        dataIndex: 'lesson',
        render: (value) => {
          const last_lesson = _.maxBy(value, 'end_datetime');
          if (_.isEmpty(last_lesson)) return "-";
          return Helper.displayDate(last_lesson.end_datetime);
        },
      },
      {
        title: t('course_status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayCourseStatus(value),
      },
      {
        title: t('price'),
        dataIndex: 'price',
        // render: (value) => (value ? moment.unix(value).format('YYYY-MM-DD HH:mm:ss') : ''),
      },
      {
        title: t('target_student_no'),
        dataIndex: 'target_student_no',
      },
      {
        title: t('description'),
        dataIndex: 'description',
      },
    ];
    return columns
  }

  const setAttendanceTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: `lesson_id`,
        render: (value, record) => (
          <Button
            shape="circle"
            style={{ color: '#000000' }}
            icon={<SearchOutlined />}
            onClick={async () => {
              const list = await apiRoutes.getAttendanceListByLesson(value);
              setSelectedLesson(record);
              setAttendanceList(list);
              setModalVisible(true);
            }}
          />
        ),
      },
      {
        title: t('date'),
        dataIndex: `start_datetime`,
        render: (value) => Helper.displayDate(value),
      },
      {
        title: t('start_time'),
        dataIndex: `start_datetime`,
        render: (value) => Helper.displayTime(value),
      },
      {
        title: t('end_time'),
        dataIndex: `end_datetime`,
        render: (value) => Helper.displayTime(value),
      },
      // {
      //   title: t('is_finished'),
      //   dataIndex: 'is_finished',
      //   render: (value) => Helper.displayIsFinishStatus(value),
      // },
    ];
    return columns
  }

  const refresh = () => {
    setModalVisible(false);
    getData();
  }

  return (
    <>
      <Row gutter={[24, 24]} align="bottom">
        <Col span={6}>
          <Input
            value={searchCourseCode}
            onChange={(event) => setSearchCourseCode(event.target.value)}
            placeholder={t('search_course')}
          />
        </Col>
        <Col>
          <Button
            disabled={loading}
            onClick={() => {
              getData();
            }}
            type="primary"
          >
            {t('submit')}
          </Button>
        </Col>
      </Row>
      <Divider />
      {dataList.length > 1
        && (
        <Spin
          indicator={(
            <svg
              className="spinner"
              viewBox="0 0 50 50"
            >
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          )}
          spinning={loading}
        >
          <Table
            title={() => t('course_list')}
            locale={{ emptyText: loading && (<div style={{ height: 166 }} />) }}
            rowKey={tableIDName}
            scroll={{ x: 'max-content' }}
            dataSource={dataList}
            columns={setCourseTableHeader()}
          />
        </Spin>
        )}
      {!_.isEmpty(selectedCourse) && (
        <Spin
          indicator={(
            <svg
              className="spinner"
              viewBox="0 0 50 50"
            >
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          )}
          spinning={loading}
        >
          <Table
            title={() => `${selectedCourse.course_code} ${t('attendance')}`}
            locale={{ emptyText: loading && (<div style={{ height: 166 }} />) }}
            rowKey={subTableIDName}
            scroll={{ x: 'max-content' }}
            dataSource={selectedCourse.lesson || []}
            columns={setAttendanceTableHeader()}
            // expandable={{
            //   expandedRowRender: (record) => (),
            //   rowExpandable: (record) => record.name !== 'Not Expandable',
            // }}
          />
        </Spin>
      )}

      <Modal
        width="80%"
        title={`${selectedCourse.course_code} (${Helper.displayMoment(selectedLesson.start_datetime)}-${Helper.displayTime(selectedLesson.end_datetime)})`}
        visible={modalVisible}
        footer={false}
        onCancel={() => {
          setModalVisible(false);
        }}
      >
        <LessonAttendanceTable
          dataList={attendanceList}
          displayCourse={false}
          displayLesson={false}
          refresh={refresh}
        />
      </Modal>
    </>
  )
}

export default CourseAttendanceTable;
