import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CourseLessonModal from '../../components/course/CourseLessonModal';
import CourseModal from '../../components/course/CourseModal';
import CourseTable from '../../components/course/CourseTable';
import NavAndSideFrame from '../../components/NavAndSideFrame';

import apiRoutes from '../../core/api';

const selectedKey = 'course';
const openKey = 'course_management';

const { TabPane } = Tabs;

const CourseList = (props) => {
  const { t } = useTranslation();
  const title = t('course_list')

  const [init, setInit] = useState(true);
  const [activeTab, setActiveTab] = useState('user');
  const [dataObj, setDataObj] = useState({});
  const [lessonObj, setLessonObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [lessonModalVisible, setLessonModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  const [locationList, setLocationList] = useState([]);
  const [coachList, setCoachList] = useState([]);

  useEffect(() => {
    getAreaList();
    getCoachList();
  }, [callRefresh]);

  const getAreaList = async () => {
    const list = await apiRoutes.getAreaList();
    setLocationList(list);
  }

  const getCoachList = async () => {
    const list = await apiRoutes.getCoachList();
    setCoachList(list);
  }

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          if (init && key !== 'course') {
            setInit(false);
            setCallRefresh(true);
          }
        }}
      >
        <TabPane tab={t('course_list')} key="course">
          <CourseTable
            active={activeTab === 'course'}
            setModalVisible={setModalVisible}
            setLessonModalVisible={setLessonModalVisible}
            setDataObj={setDataObj}
            setLessonObj={setLessonObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
            locationList={locationList}
            coachList={coachList}
          />
        </TabPane>
      </Tabs>

      <CourseModal
        dataObj={dataObj}
        setDataObj={setDataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
        locationList={locationList}
        coachList={coachList}
      />

      <CourseLessonModal
        dataObj={dataObj}
        lessonObj={lessonObj}
        setDataObj={setDataObj}
        setLessonObj={setLessonObj}
        modalVisible={lessonModalVisible}
        setModalVisible={setLessonModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default CourseList;
