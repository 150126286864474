import * as Service from '../Service';

export async function login(postObj) {
  let resp = await Service.call('post', `/api/auth/admin`, postObj, false);
  if (resp.status < 1) {
    return resp;
  }

  resp = await Service.call('get', '/api/admin');

  return resp;
}


export async function logout() {
  
}
