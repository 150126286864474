import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  Form,
  Select,
  Button,
  Modal,
  notification,
  Row,
  Col,
  DatePicker,
  Switch,
  Input,
  message,
  Radio,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Main from '../../core/Main';
import TinymceWrapper from '../TinymceWrapper';
import * as Service from '../../core/Service';

const FaqModal = (props) => {
  const {
    dataObj,
    modalVisible,
    setModalVisible,
  } = props
  const { t } = useTranslation();
  const [action, setAction] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      destroyOnClose
      title={action === 'post' ? t('create') : t('edit')}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 800, top: 30 }}
      onCancel={() => {
        form.resetFields()
        setModalVisible(false)
      }}
      centered
    >
      <FaqForm {...props} form={form} action={action} />

    </Modal>
  )
}

const FaqForm = (props) => {
  const [submitLoading, setSubmitLoading] = useState(true)
  const app = useSelector((state) => state.app)
  const { t } = useTranslation();
  const {
    dataObj, modalVisible, setModalVisible, setCallRefresh, form,
  } = props

  useEffect(() => {
    if (modalVisible && !_.isEmpty(dataObj)) {
      form.setFieldsValue({
        ...dataObj,
        faq_id: _.toInteger(dataObj.faq_id),
      })
    }
    setSubmitLoading(false);
  }, [dataObj, modalVisible]);

  const onFinish = async (values) => {
    setSubmitLoading(true)
    const itemObj = {
      ...values,
    }
    let resp;
    if (dataObj.faq_id) {
      resp = await Service.call('put', `/api/admin/faq/${dataObj.faq_id}`, itemObj);
    } else {
      resp = await Service.call('post', '/api/admin/faq', itemObj);
    }

    setSubmitLoading(false);
    if (resp.status <= 0) {
      return;
    }

    form.resetFields();
    setModalVisible(false)
    setCallRefresh(true)
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      name="faq-form"
      layout="vertical"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label={t('title')}
            name="title"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('sorting')}
            name="sorting"
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={0}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('status')}
            name="is_active"
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={[
                { label: t('active'), value: 1 },
                { label: t('inactive'), value: 0 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('content')}
            name="content"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <TinymceWrapper
              initialValue={form.getFieldValue('content')}
              onChange={(value) => {
                form.setFieldsValue({ content: value })
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          loading={submitLoading}
          disabled={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default FaqModal;
