import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import _ from 'lodash';
import moment from 'moment'
import NavAndSideFrame from '../components/NavAndSideFrame';
import LocationTable from '../components/location/LocationTable';

const selectedKey = "location";
const openKey = "app_config";

const LocationList = (props) => {
  const { t } = useTranslation()

  return (
    <NavAndSideFrame title={t('location')} selectedKey={selectedKey} openKey={openKey}>
      <LocationTable />
    </NavAndSideFrame>
  )
}


export default LocationList;
