import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  notification,
  Tabs,
  Row,
  Col,
  Radio
} from "antd";
import { useTranslation } from "react-i18next";
import Joi from 'joi';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAdmin, setAuth } from '../../redux/actions/common'
import * as Main from "../../core/Main";
import apiRoutes from "../../core/api";
import * as FormOptions from '../../core/helper/FormOptions';

const { Option } = Select;
const { TabPane } = Tabs;

const AdminModal = (props) => {
  const {
    dataObj,
    setDataObj = () => {},
    modalVisible,
    setModalVisible,
    setCallRefresh,
    profile
  } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState('');
  const admin = useSelector((state) => state.app.admin)

  useEffect(() => {
    setAction(_.isEmpty(dataObj) ? 'post' : 'put');
  }, [dataObj])

  return (
    <Modal
      centered
      destroyOnClose
      title={`${action === 'post' ? t('create') : t('edit')} ${!profile ? t("admin") : t('admin_profile')}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setModalVisible(false);
        setDataObj({})
      }}
    >
      {action === 'post'
        ? <UserProfileForm {...props} action={action} />
        : (
          <Tabs type="card" defaultActiveKey="user-info-form">
            <TabPane tab={t('admin_profile')} key="user-info-form">
              <UserProfileForm {...props} action={action} />
            </TabPane>
            {!_.isEmpty(dataObj) && (
            <TabPane tab={t('change_password')} key="change-pw-form">
              <ChangePasswordForm
                profile={profile}
                admin_id={dataObj.admin_id}
                setModalVisible={setModalVisible}
                setCallRefresh={setCallRefresh}
              />
            </TabPane>
            )}
          </Tabs>
        )}
    </Modal>
  );
};

const UserProfileForm = (props) => {
  const {
    profile, dataObj, action, setModalVisible, setCallRefresh
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const admin = useSelector((state) => state.app.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue(dataObj);
    if (dataObj.phone && _.includes(dataObj.phone, '-')) {
      let fullPhone = _.split(dataObj.phone, '-')
      form.setFieldsValue({...dataObj, phone: fullPhone[1], phonePrefix: fullPhone[0]});
    }
  }, []);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    let {phonePrefix, phone} = values;
    const itemObj = {
      ...values,
      phone: _.join([phonePrefix, phone], '-'),
    };
    let apiFunction;
    if (_.isEmpty(dataObj)) {
      apiFunction = apiRoutes.postCoach;
    } else {
      itemObj.admin_id = dataObj.admin_id
      apiFunction = apiRoutes.putCoach;
    }

    const resp = await apiFunction(itemObj);
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    if (!profile) {
      setCallRefresh(true);
    }
    const userData = await apiRoutes.getCoach()
    dispatch(setAdmin(userData.data));
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="user_profile_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={t('mobile')}
            name="phone"
            validateFirst
            rules={[{ required: true, message: t('input_required') },
              () => ({
                validator(rule, value) {
                  if (_.toInteger(value) > 0 || _.isEmpty(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Invalid phone number'));
                }
              })]}
          >
            <Input addonBefore={FormOptions.prefixSelector(!_.isEmpty(dataObj))} />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            label={t('email')}
            name="email"
            rules={[{ type: 'email', message: 'Incorrect email format.'}]}
          >
            <Input disabled={!_.isEmpty(dataObj)} />
          </Form.Item>
        </Col> */}
        <Col span={12}>
          <Form.Item
            label={t('nickname')}
            name="nickname"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('first_name')}
            name="first_name"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('last_name')}
            name="last_name"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('status')}
            name="is_active"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.isActiveOptions()}
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            label={t('level')}
            name="privilege_level"
            rules={[{ required: false, message: t('input_required') }]}
            initialValue={1}
          >
            <Select>
              <Option value={1}>Super Admin</Option>
              <Option value={2}>Normal Admin</Option>
            </Select>
          </Form.Item>
        </Col> */}
      </Row>
      {action === 'post' && (
        <>
          <Form.Item
            name="password"
            label={t('password')}
            validateFirst
            rules={[
              {
                required: true,
                message: t('input_required'),
              },
              () => ({
                validator(_, value) {
                  const schema = Joi.string().alphanum()
                  const validateResult = schema.validate(value)
                  if (validateResult.error) {
                    return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
                  }
                  return Promise.resolve();
                },
              }),
              {
                min: 8,
                max: 10
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label={t('confirm_password')}
            dependencies={['password']}
            validateFirst
            hasFeedback
            rules={[
              {
                required: true,
                message: t('input_required'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('password_not_match')));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

const ChangePasswordForm = (props) => {
  const {
    profile, admin_id, setModalVisible, setCallRefresh
  } = props;
  const history = useHistory()
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [submitLoading, setSubmitLoading] = useState(false);
  const admin = useSelector((state) => state.app.admin)

  const [logout, setLogout] = useState(false);

  useEffect(() => {
    if (logout) return history.push('/admin/login')
  }, [logout])


  const onFinish = async (values) => {
    setSubmitLoading(true)
    const itemObj = values;
    let resp = await apiRoutes.putCoachChangePassword(admin_id, itemObj);
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false)
    if (!profile) {
      setCallRefresh(true)
    } else {
      dispatch(setAdmin({}))
      dispatch(setAuth(false))
      setLogout(true)
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="change_pw_form"
      onFinish={onFinish}
    >
      {profile && (
      <Form.Item
        name="old_password"
        label={t('old_password')}
        validateFirst
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          () => ({
            validator(_, value) {
              const schema = Joi.string().alphanum()
              const validateResult = schema.validate(value)
              if (validateResult.error) {
                return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
              }
              return Promise.resolve();
            },
          }),
          {
            min: 8,
            max: 10
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      )}

      <Form.Item
        name="new_password"
        label={t('password')}
        validateFirst
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          () => ({
            validator(_, value) {
              const schema = Joi.string().alphanum()
              const validateResult = schema.validate(value)
              if (validateResult.error) {
                return Promise.reject(new Error(t('alphanum_required', { label: t('password') })));
              }
              return Promise.resolve();
            },
          }),
          {
            min: 8,
            max: 10
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label={t('confirm_password')}
        dependencies={['password']}
        validateFirst
        hasFeedback
        rules={[
          {
            required: true,
            message: t('input_required'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('password_not_match')));
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          loading={submitLoading}
          disabled={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}


export default AdminModal;
