import * as Service from '../Service';

import _ from 'lodash';

export async function getConfig() {
  const url = `/api/admin/config`;
  const resp = await Service.call('get', url)

  return resp;
}

export async function getAdmin() {
  const url = `/api/admin`;
  const resp = await Service.call('get', url)

  return resp;
}
