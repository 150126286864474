import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import * as Service from '../../core/Service';
import NavAndSideFrame from '../../components/NavAndSideFrame';
import { AdminTable, AdminModal } from '../../components/admin';

const { TabPane } = Tabs;

const selectedKey = 'admin';
const openKey = 'admin_management';

const AdminList = (props) => {
  const { t } = useTranslation();
  const title = t('admin_list')
  const [init, setInit] = useState(true);
  const [activeTab, setActiveTab] = useState('admin');
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);


  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          if (init && key !== 'admin') {
            setInit(false);
            setCallRefresh(true);
          }
        }}
      >
        <TabPane tab={t('admin_list')} key="admin">
          <AdminTable
            active={activeTab === 'admin'}
            setModalVisible={setModalVisible}
            setDataObj={setDataObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
      </Tabs>
      <AdminModal
        dataObj={dataObj}
        setDataObj={setDataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </NavAndSideFrame>
  )
}

export default AdminList;
