import React from 'react';

import Table from "./AdminTable";
import Modal from "./AdminModal";

const componentConfig = {
  showEmail: false,
  showMobile: true,
  showCtime: false,
  showUtime: false,
}

const AdminTable = (props) => {
  return <Table {...props} componentConfig={componentConfig} />;
}
const AdminModal = (props) => {
  return <Modal {...props} componentConfig={componentConfig} />;
}

export {
  AdminTable,
  AdminModal
};
