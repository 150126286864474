import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { notification } from 'antd';
import * as Main from '../core/Main';
import * as Service from '../core/Service';

const useFetch = ({
  url = null, // endpoint
  queryParams = {}, // query parameters
  order = { key: [], value: [] }, // ordering templates
  callRefresh = true, // refresh dependency
  setCallRefresh = () => {},
  dependencies = [] // fetching api dependencies
}) => {
  const [data, setData] = useState(null)
  const [refresh, setRefresh] = useState(callRefresh);

  useEffect(() => {
    // trigger fetching API...
    if (refresh) {
      fetchData()
    }
  }, [refresh, ...dependencies])

  useEffect(() => {
    // set refresh locally
    setRefresh(() => callRefresh)
  }, [callRefresh])

  const fetchData = useCallback(async () => {
    try {
      let dataSource = null
      setRefresh(true)
      const resp = await Service.call('get', url, queryParams);

      // error handling
      if (!resp || resp.status <= 0) {
        return notification.error({
          message: Main.getErrorCodeMsg(resp.errorCode, resp.errorMessage),
          duration: 0
        });
      }

      dataSource = resp.data;
      // order
      if (!_.isEmpty(order.key) && !_.isEmpty(order.value)) {
        dataSource = _.orderBy(dataSource, order.key, order.value)
      }
      setData(() => dataSource)
      setRefresh(false)
      setCallRefresh(false)
    } catch (error) {
      console.error(error)
    }
  }, [url, order])

  return [
    data,
    setData,
    refresh,
    setRefresh
  ]
}

export default useFetch;
