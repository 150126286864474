import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin, Image, Popconfirm
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  RocketOutlined,
  QrcodeOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import * as Service from '../../core/Service';
import { useFetch } from '../../hooks';
import * as Helper from '../../core/helper/RenderDisplay';
import { useSelector } from 'react-redux';
import QrCodePreview from '../QrCodePreview';
const { Option } = Select

const tableIDName = "user_id";

const UserTable = (props) => {
  const app = useSelector((state) => state.app);
  const { config } = app;
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [exportDateRange, setExportDateRange] = useState([
    null,
    null
  ])
  const [limit, setLimit] = useState(null);
  const [reset, setReset] = useState(false)
  const {
    setModalVisible,
    setDataObj,
    callRefresh,
    setCallRefresh,
  } = props;

  useEffect(() => {
    if (reset) {
      setRefresh(true)
      setExportDateRange([null, null])
      setLimit(null)
      setReset(false)
    }
  }, [reset])

  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/user/range',
    queryParams: {
      start: exportDateRange[0] ? exportDateRange[0].unix() : null,
      end: exportDateRange[1] ? exportDateRange[1].unix() : null,
      limit: limit || null
    },
    order: { key: ['is_active', 'utime'], value: ['desc', 'desc'] },
    callRefresh,
    setCallRefresh
  })


  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <Row gutter={8}>
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setDataObj(record);
                      setModalVisible(true);
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={t('delete')}>
                  <Popconfirm
                    title={t('confirm_delete')}
                    placement="right"
                    onConfirm={async () => {
                      await Service.call('delete', `/api/admin/user/${value}`)
                      setRefresh(true)
                    }}
                    cancelText={t('cancel')}
                    okText={t('ok')}
                  >
                    <Button
                      shape="circle"
                      style={{ color: 'red' }}
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </Tooltip>
              </Col>
              {record.user_no && (
                <Col>
                  <QrCodePreview id={record.user_no} />
                </Col>
              )}
            </Row>
          )
        }
      },
      {
        title: t('user_id'),
        dataIndex: `user_no`,
        sorter: (a, b) => a.user_no.localeCompare(b.user_no),
      },
      {
        title: t('name'),
        dataIndex: `first_name`,
        render: (val, record) => {
          return `${record?.first_name ?? ''} ${record?.last_name ?? ''}`
        }
      },
      {
        title: t('age'),
        dataIndex: `age`,
      },
      {
        title: t('mobile'),
        dataIndex: 'phone',
      },
      {
        title: t('email'),
        dataIndex: 'email',
      },
      {
        title: t('course_application_record'),
        dataIndex: 'utime',
      },
      {
        title: t('course_record'),
        dataIndex: 'ctime',
      },
      {
        title: t('attendance_record'),
        dataIndex: 'ctime',
      },
      // {
      //   title: t('qrcode'),
      //   dataIndex: 'qrcode',
      //   render: (value) => <span><QrcodeOutlined />TBC</span>,
      // },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
      },
    ];
    return columns
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setDataObj({});
          setModalVisible(true);
        }}
      >
        {t('add_user')}
      </Button>
      <Divider />
      <Row gutter={[24, 24]} align="bottom">
        <Col span={4}>
          <div style={{ marginBottom: 6 }}>{t(`limit_record`)}</div>
          <Select
            disabled={refresh}
            optionFilterProp="label"
            style={{ width: '100%' }}
            defaultValue={null}
            placeholder={t('select')}
            value={limit}
            onChange={(value) => {
              setLimit(value)
              setRefresh(true)
            }}
          >
            {_.times(10, (num) => {
              return (
                <Option key={num} value={100 * (num + 1)}>{100 * (num + 1)}</Option>
              )
            })}
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar
            fields={[
              'user_id',
              'first_name',
              'last_name',
              'phone',
              'email',
            ]}
            data={dataList}
            setData={setDataList}
            refresh={refresh}
            reset={reset}
          />
        </Col>
        <Col>
          <Button
            disabled={refresh}
            onClick={() => {
              setReset(true)
            }}
            type="primary"
            icon={<ClearOutlined />}
          >
            {t('clear_filter')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
        />
      </Spin>
    </>
  )
}

export default UserTable;
