import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import NotificationForm from './NotificationForm';

const NotificationModal = (props) => {
  const {t} = useTranslation();
  const {setCallRefresh, modalVisible, setModalVisible} = props;

  const refresh = () => {
    setCallRefresh(true);
    setModalVisible(false);
  }

  return (
    <Modal
      title={t('add_notification')}
      visible={modalVisible}
      onCancel={refresh}
      footer={false}
      bodyStyle={{padding: '12px 24px'}}
    >
      <NotificationForm
        refresh={refresh}
      />
    </Modal>
  )
};

export default NotificationModal;
