import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  auth: false,
  config: {
    STATIC_SERVER_URL: null,
  },
  loading: false,
  admin: {},
  origin: '',
  logoutWay: '',
  locations: [],
  language: 'en'
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CONFIG: {
        return {...state, config: action.data};
      }
      case types.SET_AUTH:
        return {...state, auth: action.data}
      case types.SET_LOADING:
        return {...state, loading: action.data};
      case types.SET_ADMIN:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            admin: initialState.admin,
          };
        }
        return {...state, admin: action.data};

      case types.SET_ORIGIN:
        return {...state, origin: action.data}
      case types.SET_LOGOUTWAY:
        return {...state, logoutWay: action.data}
      case types.SET_LOCATION:
        return {...state, locations: action.data}
      case types.SET_LANGUAGE:
        return {...state, language: action.data}
      default: return state
    }
  }
};

export default appReducer;
