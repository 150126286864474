import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin, List
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  RocketOutlined,
  QrcodeOutlined,
  SearchOutlined,
  StopOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import { useFetch } from '../../hooks';
import * as Helper from '../../core/helper/RenderDisplay'
import { Link, useHistory } from 'react-router-dom';

import apiRoutes from '../../core/api';

const { Option } = Select

const tableIDName = "course_id";
const subTableIDName = "lesson_id";

const dateFormat = 'YYYY-MM-DD';
const timeFormat = 'HH:mm';


const CourseTable = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [exportDateRange, setExportDateRange] = useState([
    null,
    null
  ])
  const [limit, setLimit] = useState(null);
  const [reset, setReset] = useState(false)
  const {
    setModalVisible,
    setDataObj,
    callRefresh,
    setCallRefresh,
    setLessonModalVisible,
    setLessonObj,
    coachList,
  } = props;

  useEffect(() => {
    if (reset) {
      setRefresh(true)
      setExportDateRange([null, null])
      setLimit(null)
      setReset(false)
    }
  }, [reset])

  const [areaList, setAreaList] = useState([]);
  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/course',
    order: { key: ['is_active', 'utime'], value: ['desc', 'desc'] },
    callRefresh,
    setCallRefresh
  })

  const setCourseTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        key: tableIDName,
        render: (value, record) => {
          return (
            <Row gutter={12}>
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<SearchOutlined />}
                    onClick={() => {
                      // history.push(`/admin/course/${record.course_code}`)
                      history.push({
                        pathname: `/admin/course/${record.course_code}`,
                        state: { courseCode: record.course_code }
                      })
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title={t('stop')}>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<StopOutlined />}
                    onClick={async () => {
                      apiRoutes.putCourse({
                        [tableIDName]: value,
                        is_active: 0,
                      });
                      setRefresh(true)
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('course_code'),
        dataIndex: `course_code`,
      },
      {
        title: t('course_name'),
        dataIndex: `course_name`,
      },
      {
        title: t('description'),
        dataIndex: 'course_description',
      },
      {
        title: t('coach'),
        dataIndex: `admin_id`,
        render: (value) => {
          const coach = _.find(coachList, { admin_id: value });
          if (_.isUndefined(coach)) return "";
          return coach.nickname || (coach.first_name)
        },
      },
      {
        title: t('area'),
        dataIndex: `area`,
        filters: areaList,
        onFilter: (value, record) => _.isArray(value) ? value.includes(record.data) : value === record.area,
        render: (value) => value.area_name,
      },
      {
        title: t('start_date'),
        dataIndex: `lesson`,
        render: (value) => {
          const first_lesson = _.minBy(value, 'start_datetime');
          if (_.isUndefined(first_lesson)) return "-";
          return Helper.displayDate(first_lesson.start_datetime);
        },
      },
      {
        title: t('end_date'),
        dataIndex: 'lesson',
        render: (value) => {
          const last_lesson = _.maxBy(value, 'end_datetime');
          if (_.isUndefined(last_lesson)) return "-";
          return Helper.displayDate(last_lesson.end_datetime);
        },
      },
      {
        title: t('lesson'),
        dataIndex: 'lesson',
        render: (value) => {
          const lesson = _.groupBy(value, 'dayOfWeek');

          const lessonOfDay = _.keys(lesson);

          return `每週${lessonOfDay.length}堂`;
        },
      },
      {
        title: t('time'),
        dataIndex: 'lesson',
        width: 180,
        render: (value) => {
          const lesson = _.groupBy(value, 'dayOfWeek');

          const lessonOfDay = _.keys(lesson);

          return (
            <Row>
              <Col>
                {_.map(lessonOfDay, (day) => {
                  const start_end_time = _.map(lesson[day], (l) => { return { start_time: l.start_time, end_time: l.end_time } });
                  const timeslot = _.uniqBy(start_end_time, 'start_time', 'end_time');

                  // TODO: translation
                  return (
                    <Row>
                      <Col span={12}>
                        {t(day)}
                      </Col>
                      <Col span={12}>
                        <Row justify="space-between">
                          {_.map(timeslot, (slot) => <Col><Tag>{slot.start_time}-{slot.end_time}</Tag></Col>)}
                        </Row>
                      </Col>
                    </Row>
                  )
                })}
              </Col>
            </Row>
          )
        },
      },
      {
        title: t('course_status'),
        dataIndex: 'status',
        render: (value) => Helper.displayCourseStatus(value),
      },
      {
        title: t('price'),
        dataIndex: 'price',
        render: (value) => Helper.displayPrice(value),
      },
      {
        title: t('target_student_no'),
        dataIndex: 'target_student_no',
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
      },
    ];
    return columns
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setDataObj({});
          setModalVisible(true);
        }}
      >
        {t('add_course')}
      </Button>
      <Divider />
      <Row gutter={[24, 24]} align="bottom">
        <Col span={6}>
          <SearchBar
            fields={[
              'course_name',
              'course_code',
              'description'
            ]}
            data={dataList}
            setData={setDataList}
            refresh={refresh}
            reset={reset}
          />
        </Col>
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setCourseTableHeader()}
        />
      </Spin>
    </>
  )
}

export default CourseTable;
