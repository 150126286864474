import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  notification,
  Tabs,
  Row,
  Col,
  Radio,
  InputNumber,
  Space,
  DatePicker,
  TimePicker
} from "antd";
import { useTranslation } from "react-i18next";
import Joi from 'joi';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAdmin, setAuth } from '../../redux/actions/common'
import * as Main from "../../core/Main";
import * as Service from "../../core/Service";
import moment from "moment";

import * as FormOptions from '../../core/helper/FormOptions';
import * as RenderDisplay from '../../core/helper/RenderDisplay';

const { Option } = Select;

const CourseForm = (props) => {
  const {
    dataObj, action, locationList, setModalVisible = () => {}, setCallRefresh, coachList
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue(dataObj);
  }, [dataObj]);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    let { phonePrefix, phone } = values;
    const itemObj = {
      ...values,
      phone: phone ? _.join([phonePrefix, phone], '-') : null,
      regular_user_id: _.toInteger(values.regular_user_id)
    };
    let endpoint = '/api/admin/course'

    if (action === 'put') itemObj.course_id = dataObj.course_id;

    const resp = await Service.call(action, endpoint, itemObj)
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    setCallRefresh(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="course_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label={t('course_name')}
            name="course_name"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={t('course_description')}
            name="course_description"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Input.TextArea maxLength={128} showCount />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('coach')}
            name="admin_id"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Select>
              {FormOptions.generateSelectOptionsWithLabel(coachList, 'admin_id', (coach) => coach && (coach.nickname || coach.first_name))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('target_student_no')}
            name="target_student_no"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <InputNumber
              min={0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('price')}
            name="price"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <InputNumber
              formatter={RenderDisplay.displayPrice}
              min={0}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('course_status')}
            name="status"
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.courseStatusOptions()}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('status')}
            name="is_active"
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.isActiveOptions()}
            />
          </Form.Item>
        </Col>
        <Form.Item
          label={t('is_finished')}
          name="is_finished"
          rules={[{ required: true, message: t('input_required') }]}
          initialValue={0}
          hidden
        >
          <Radio.Group options={FormOptions.isFinishedOptions()} />
        </Form.Item>

        <Col span={24}>
          <Form.Item
            label={t('location')}
            name="location_id"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <Select
              style={{ width: '100%' }}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
              {FormOptions.generateSelectOptionsWithLabel(locationList, 'location_id', (location) => location.swimming_pool)}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('remark')}
            name="remark"
            rules={[{ required: false, message: t('input_required') }]}
          >
            <Input.TextArea showCount maxLength={255} />
          </Form.Item>
        </Col>

      </Row>

      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CourseForm;
