import _ from 'lodash';
import {Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import apiRoutes from '../../core/api';
import CourseForm from './CourseForm';

const CourseInformation = (props) => {
  const {courseCode, callRefresh, setCourse, course} = props;

  useEffect(() => {
    if (callRefresh) getData();
  }, [callRefresh, courseCode]);

  const getData = async () => {
    const resp = await apiRoutes.getCourseByCourseCode(courseCode);
    setCourse(resp);
  }

  if (_.isEmpty(course) || callRefresh) {
    return <Spin />;
  }

  return (
    <CourseForm
      {...props}
      dataObj={course}
      action="put"
    />
  )
};

export default CourseInformation;
