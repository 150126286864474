import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  notification,
  Tabs,
  Row,
  Col,
  Radio,
  InputNumber,
  Space,
  DatePicker,
  TimePicker
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import Joi from 'joi';
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setAdmin, setAuth } from '../../redux/actions/common'
import * as Main from "../../core/Main";
import apiRoutes from "../../core/api";
import moment from "moment";

import * as FormOptions from '../../core/helper/FormOptions';
import { defaultDateFormat, defaultDateTimeFormat, defaultTimeFormat } from "../../styles/DisplayFormat";

const { Option } = Select;
const { TabPane } = Tabs;

const CourseLessonModal = (props) => {
  const {
    dataObj,
    lessonObj,
    setDataObj,
    modalVisible,
    setModalVisible,
  } = props;
  const { t } = useTranslation();
  const [action, setAction] = useState('');

  useEffect(() => {
    setAction(_.isEmpty(lessonObj) ? 'post' : 'put');
  }, [lessonObj, dataObj])

  return (
    <Modal
      centered
      title={`${action === 'post' ? t('create') : t('edit')} ${t("lesson")}`}
      visible={modalVisible}
      footer={null}
      width="95vw"
      style={{ maxWidth: 1000 }}
      bodyStyle={{ minHeight: 300 }}
      onCancel={() => {
        setDataObj({})
        setModalVisible(false);
      }}
    >
      <CourseLessonForm {...props} action={action} />
    </Modal>
  );
};

const CourseLessonForm = (props) => {
  const {
    dataObj, action, setModalVisible, setCallRefresh, lessonObj, setDataObj, setLessonObj
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    // form.setFieldsValue(lessonObj);
    const formObj = _.clone(lessonObj);
    if (!_.isEmpty(lessonObj)) {
      formObj.date = moment.unix(lessonObj.start_datetime);
      formObj.start_time = moment.unix(lessonObj.start_datetime);
      formObj.end_time = moment.unix(lessonObj.end_datetime);
    }
    console.log(lessonObj)
    form.setFieldsValue(formObj);
  }, [dataObj, lessonObj]);

  const onFinish = async (values) => {
    setSubmitLoading(true);
    const {is_finished, is_active, remark} = values;

    const date = values.date.format(defaultDateFormat);
    const start_time = values.start_time.format(defaultTimeFormat);
    const end_time = values.end_time.format(defaultTimeFormat);

    const start_datetime = moment(`${date} ${start_time}`, defaultDateTimeFormat).unix();
    const end_datetime = moment(`${date} ${end_time}`, defaultDateTimeFormat).unix();

    const itemObj = {
      start_datetime,
      end_datetime,
      course_id: dataObj.course_id || lessonObj.course_id,
      is_finished,
      remark,
      is_active,
    }

    let apiFunction = () => {};
    if (action === 'put') {
      itemObj.lesson_id = lessonObj.lesson_id;
      apiFunction = apiRoutes.putCourseLesson;
    } else {
      apiFunction = apiRoutes.postCourseLesson;
    }

    console.log(lessonObj, itemObj)

    const resp = await apiFunction(itemObj)
    setSubmitLoading(false);
    if (resp.status < 1) {
      return;
    }
    form.resetFields();
    setModalVisible(false);
    setDataObj({});
    setLessonObj({});
    setCallRefresh(true);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name="lesson_form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label={t('date')}
            name="date"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <DatePicker format={defaultDateFormat} placeholder={t('display_date')}/>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('start_time')}
            name="start_time"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <TimePicker format={defaultTimeFormat} placeholder={t('start_time')}/>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('end_time')}
            name="end_time"
            rules={[{ required: true, message: t('input_required') }]}
          >
            <TimePicker format={defaultTimeFormat} placeholder={t('end_time')}/>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t('remark')}
            name="remark"
            rules={[{ required: false, message: t('input_required') }]}
          >
            <Input.TextArea showCount maxLength={255} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('status')}
            name="is_active"
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={1}
          >
            <Radio.Group
              options={FormOptions.isActiveOptions()}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t('is_finished')}
            name="is_finished"
            // hidden={action === 'post'}
            hidden
            rules={[{ required: true, message: t('input_required') }]}
            initialValue={0}
          >
            <Radio.Group
              options={FormOptions.isFinishedOptions()}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          htmlType="submit"
        >
          {t('submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CourseLessonModal;
