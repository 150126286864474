import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Table, Tooltip, Tag, notification,
  Popconfirm, Typography, Spin, Divider
} from 'antd';
import {
  StopOutlined,
  CheckOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import * as Helper from "../../core/helper/RenderDisplay";
import * as Service from "../../core/Service";
import { useFetch } from '../../hooks';
import SearchBar from '../SearchBar';

const tableIDName = "id";

const LocationTable = () => {
  const { t } = useTranslation()

  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: `/api/admin/location`,
    order: { key: ['level'], value: ['asc']},
  })

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record, index) => {
          return (
            <Row gutter={[10, 0]}>
              <Col>
                <Tooltip title={record.status !== 'Active' ? t('activate') : t('deactivate')}>
                  <Button
                    shape="circle"
                    style={{ color: record.status !== 'Active' ? 'green' : 'red'}}
                    icon={record.status === 'Active' ? <StopOutlined /> : <CheckOutlined />}
                    onClick={async () => {
                      await Service.call('put', `/api/admin/location/${value}`, {
                        status: record.status === 'Active' ? 'Inactive' : 'Active'
                      })
                      setRefresh(true)
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value)
      },
      {
        title: t('area'),
        dataIndex: 'area_name',
      },
      {
        title: t('address'),
        dataIndex: 'address',
      },
      {
        title: t('remark'),
        dataIndex: 'remark',
      },
    ];
    return columns;
  }

  return (
    <>
      <Row gutter={[20, 20]}>
        <SearchBar
          fields={[
            'status',
            'name',
            'location_id'
          ]}
          data={dataList}
          setData={setDataList}
          refresh={refresh}
        />
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
          bordered
        />
      </Spin>
    </>
  )
}
export default LocationTable;
