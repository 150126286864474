import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CourseAttendanceTable from '../../components/attendance/CourseAttendanceTable';
import StudentAttendanceTable from '../../components/attendance/StudentAttendanceTable';
import NavAndSideFrame from '../../components/NavAndSideFrame';

const selectedKey = 'attendance';
const openKey = 'attendance_management';

const { TabPane } = Tabs;

const AttendanceList = (props) => {
  const { t } = useTranslation();
  const title = t('attendance')

  const [init, setInit] = useState(true);
  const [activeTab, setActiveTab] = useState('user');
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [callRefresh, setCallRefresh] = useState(true);

  return (
    <NavAndSideFrame title={title} selectedKey={selectedKey} openKey={openKey}>
      <Tabs
        type="card"
        onChange={(key) => {
          setActiveTab(key);
          if (init && key !== 'course') {
            setInit(false);
            setCallRefresh(true);
          }
        }}
      >
        <TabPane tab={t('by_course')} key="course">
          <CourseAttendanceTable
            active={activeTab === 'course'}
            setModalVisible={setModalVisible}
            setDataObj={setDataObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
          />
        </TabPane>
        {/* <TabPane tab={t('by_student')} key="student">
          <StudentAttendanceTable
            active={activeTab === 'student'}
            setModalVisible={setModalVisible}
            setDataObj={setDataObj}
            callRefresh={callRefresh}
            setCallRefresh={setCallRefresh}
          />
        </TabPane> */}
      </Tabs>
    </NavAndSideFrame>
  )
}

export default AttendanceList;
