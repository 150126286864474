import React, { Component } from 'react';
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Login from './pages/Login';
import Home from './pages/Home';
import SiteMetaList from './pages/SiteMetaList';
import UserList from './pages/user/UserList';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import NotFound from './components/NotFound';
import LocationList from './pages/LocationList';
import AdminList from './pages/admin/AdminList';
import FaqList from './pages/faq/FaqList';
import BlogList from './pages/blog/BlogList';
import CourseList from './pages/course/CourseList';
import NoticeList from './pages/notice/NoticeList';
import UserApplyList from './pages/user/UserApplyList';
import AttendanceList from './pages/attendance/AttendanceList';
import CourseDetail from './pages/course/CourseDetail';

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute
            path="/admin/login"
            component={Login}
            exact
          />
          <PrivateRoute
            path="/admin/home"
            component={Home}
            exact
          />
          <PrivateRoute
            path="/admin/location"
            component={LocationList}
            exact
          />
          <PrivateRoute
            path="/admin/user/apply"
            component={UserApplyList}
            exact
          />
          <PrivateRoute
            path="/admin/user/list"
            component={UserList}
            exact
          />
          <PrivateRoute
            path="/admin/attendance"
            component={AttendanceList}
            exact
          />
          <PrivateRoute
            path="/admin/admin/list"
            component={AdminList}
            exact
          />
          <PrivateRoute
            path="/admin/coach"
            component={AdminList}
            exact
          />
          <PrivateRoute
            path="/admin/blog"
            component={BlogList}
            exact
          />
          <PrivateRoute
            path="/admin/course"
            component={CourseList}
            exact
          />
          <PrivateRoute
            path="/admin/course/:courseCode"
            component={CourseDetail}
            exact
          />
          <PrivateRoute
            path="/admin/faq"
            component={FaqList}
            exact
          />
          <PrivateRoute
            path="/admin/notice"
            component={NoticeList}
            exact
          />
          <PrivateRoute
            path="/admin/app_config/location"
            component={LocationList}
            exact
          />
          <PrivateRoute
            path="/admin/app_config/site_meta"
            component={SiteMetaList}
            exact
          />
          <Route
            path="/404"
            component={NotFound}
            exact
          />
          <Redirect exact from="/*" to="/admin/login" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
