import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Form,
  notification,
  Modal,
  Button,
  Spin,
  message,
  InputNumber,
  Input,
} from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import apiRoutes from "../core/api";
import * as Main from '../core/Main';
import FormUploadFile from './form/FormUploadFile';
import TinymceWrapper from './TinymceWrapper';

const SiteMetaModal = (props) => {
  const app = useSelector((state) => state.app)
  const { t } = useTranslation();
  const {
    selectedRecord,
    modalVisible,
    setModalVisible,
    setCallRefresh
  } = props;
  const [systemConfigForm] = Form.useForm();
  const [newImage, setNewImage] = useState({
    filename: '',
    filepath: null,
  })

  const onUploadChange = async (info) => {
    const { status, response } = info.file;
    if (status === 'done') {
      message.destroy()
      if (response.status > 0) {
        message.success(t('success'))
        let data = {
          filename: info.file.response.filename,
          filepath: info.file.response.filepath,
        }
        setNewImage(data)
      } else {
        message.error(t('fail'))
      }
    }
  }

  useEffect(() => {
    if (modalVisible && !_.isEmpty(selectedRecord)) {
      if(selectedRecord.type === 'image'){
        setNewImage({
          filepath: selectedRecord.meta_value
        })
      }
      systemConfigForm.setFieldsValue(selectedRecord)
    }
  }, [modalVisible, selectedRecord])

  const onFinish = async (formData) => {
    let itemObj = {
      meta_value: _.toString(formData.meta_value)
    }
    if (!_.isNull(newImage.filepath)) {
      itemObj.meta_value = newImage.filepath
    }

    let resp = await apiRoutes.putSiteMeta(selectedRecord.site_meta_id, itemObj);
    if (!resp || resp.status < 1) {
      return;
    }
    setNewImage({
      filename: '',
      filepath: null,
    })
    setModalVisible(false);
    setCallRefresh(true)
  }

  const renderFormItemByType = () => {
    if (!selectedRecord) return;
    switch (selectedRecord.type) {
      case "editor":
        return (
          <Form.Item name="meta_value" label={t('content')}>
            <TinymceWrapper
              style={{ height: 400 }}
              initialValue={selectedRecord?.meta_value}
              onChange={(value) => {
                systemConfigForm.setFieldsValue({ description: value })
              }}
            />
          </Form.Item>
        )
      case "string":
        return (
          <Form.Item name="meta_value" label={t('content')}>
            <Input style={{ minWidth: 200 }} />
          </Form.Item>
        )
      case "number":
        return (
          <Form.Item name="meta_value" label={t('content')}>
            <InputNumber style={{ minWidth: 200 }} />
          </Form.Item>
        )
      case "image":
        return (
          <Form.Item name="meta_value" label={t('image')}>
            <FormUploadFile
              onChange={onUploadChange}
              type="one"
              imageURL={newImage.filepath ? `${app.config.STATIC_SERVER_URL}/${newImage.filepath}` : null}
              data={{ resize: true }}
            />
          </Form.Item>
        )
      default: break;
    }
  }

  return (
    <Modal
      destroyOnClose
      title={t('edit')}
      visible={modalVisible}
      footer={null}
      centered
      width={800}
      style={{maxWidth: "100%", top: 20 }}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={false}
      >
        <Form
          form={systemConfigForm}
          name="systemConfigForm"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label={t('meta_key')}
            rules={[{ required: true, message: t('input_required') }]}
          >
            {selectedRecord.meta_key}
          </Form.Item>
          {renderFormItemByType()}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default SiteMetaModal;
