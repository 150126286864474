import * as Service from '../Service';

export async function getUserList() {
  const resp = await Service.call('get', '/api/admin/user');
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function getUserOptionListBySearchKeywords(keyword) {
  const resp = await Service.call('post', '/api/admin/user/search_by_keyword', { keyword });
  return resp;
}

export async function getUserAppliedCourse(user_id) {
  const resp = await Service.call('get', '/api/admin/user/course', { user_id });
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function getUserAppliedCourseLesson(user_id, course_id) {
  const resp = await Service.call('get', '/api/admin/user/lesson', { user_id, course_id });
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}
