export const gender = [
  {value: "M", label: 'male', color: 'lightblue'},
  {value: "F", label: 'female', color: 'pink'},
]

export const isActive = [
  {value: 1, label: 'active', color: 'green'},
  {value: 0, label: 'inactive', color: 'red'},
  {value: undefined, label: 'fail', color: '#f50'},
  {value: null, label: 'fail', color: '#f50'},
];

export const isFinish = [
  {value: 1, label: 'finish', color: ''},
  {value: 0, label: 'not_finish', color: ''},
  {value: undefined, label: 'fail', color: ''},
  {value: null, label: 'fail', color: ''},
];

export const courseStatus = [
  {value: 1, label: 'course_success', color: ''},
  {value: 0, label: 'course_fail', color: ''},
  {value: undefined, label: 'fail', color: ''},
  {value: null, label: 'fail', color: ''},
];

export const attendanceStatus = [
  {value: 1, label: 'attended', color: 'green'},
  {value: 0, label: 'absent', color: 'red'},
  {value: -1, label: 'sick_leave', color: ''},
  {value: 2, label: 'late', color: ''},
  {value: -2, label: 'make_up_class', color: ''},
  {value: null, label: 'not_start', color: ''},
  {value: undefined, label: 'waiting_to_confirm', color: '#f50'},
];

export const paymentStatus = [
  {value: 1, label: 'confirm_paid', color: ''},
  {value: 0, label: 'waiting_confirm', color: ''},
  {value: undefined, label: 'fail', color: ''},
  {value: null, label: 'fail', color: ''},
];
