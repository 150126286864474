import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message, ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import zhTW from 'antd/lib/locale/zh_TW';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  setConfig, setAdmin, setAuth, setLanguage
} from './redux/actions/common'
import apiRoutes from './core/api';
import AppRoute from './AppRoute';

let configOrigin = '';

message.config({
  top: '75%',
  maxCount: 1,
});

const AppContainer = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.app.language)

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    // setLoading(true);
    dispatch(setLanguage(localStorage.getItem('language') || 'en'))
    let resp = await apiRoutes.getConfig();
    if (resp && resp.status > 0) {
      dispatch(setConfig(resp));
    } else {
      throw new Error('failed to get app config');
    }


    resp = await apiRoutes.getAdmin();
    if (resp && resp.status > 0) {
      dispatch(setAdmin(resp.data));
      dispatch(setAuth(true));
      setLoading(false);
      return;
    }

    dispatch(setAuth(false));
    setLoading(false);
  }

  if (loading) {
    return (
      <div style={{position: 'absolute', top: '50%', left: '50%'}}>
        <Spin
          indicator={(
            <svg
              className="spinner"
              viewBox="0 0 50 50"
            >
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
            </svg>
          )}
        />
      </div>
    );
  }

  return (
    <ConfigProvider locale={language === 'en' ? enUS : zhTW}>
      <AppRoute />
    </ConfigProvider>
  );
}

export default AppContainer;
