import * as Service from '../Service';

export async function getCoach() {
  const url = '/api/admin';
  const resp = await Service.call('get', url);
  if (resp.status === 1) {
    return resp.data;
  }
  return {};
}

export async function getCoachList() {
  const url = '/api/admin/admin';
  const resp = await Service.call('get', url);
  if (resp.status === 1) {
    return resp.data;
  }
  return [];
}

export async function putCoach(itemObj) {
  const {admin_id, ...putObj} = itemObj;
  const url = `/api/admin/admin/${admin_id}`;
  const resp = await Service.call('put', url, putObj);

  return resp;
}

export async function postCoach(postObj) {
  const url = '/api/admin/admin';
  const resp = await Service.call('post', url, postObj);

  return resp;
}

export async function deleteCoach(coachID) {
  const url = `/api/admin/admin/${coachID}`;
  const resp = await Service.call('delete', url);

  return resp;
}

export async function putCoachChangePassword(admin_id, itemObj) {
  const url = `/api/admin/admin/change_password/${admin_id}`;
  const resp = await Service.call('put', url, itemObj);

  return resp;
}