import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

const PrivateRoute = ({
  component: AppComponent,
  restricted,
}) => {
  const app = useSelector((state) => state.app);
  const havePermission = !restricted || _.includes(restricted, app.admin.type)

  return (
    <>
      {app.auth === null ? (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin
            indicator={(
              <svg
                className="spinner"
                viewBox="0 0 50 50"
              >
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
              </svg>
            )}
          />
        </div>
      ) : (
        <Route
          render={(props) => {
            return (
              app.auth && havePermission
                ? <AppComponent {...props} />
                : <Redirect to="/admin/login" />
            )
          }}
        />
      )}
    </>
  );
}

export default PrivateRoute;
