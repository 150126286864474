import React, { useRef } from 'react';
import { useSelector } from 'react-redux'
import { Editor } from '@tinymce/tinymce-react';

const debug = require('debug')('app:src:components:TinymceWrapper')

const TinymceWrapper = (props) => {
  const {
    style = { height: 300 },
    initialValue,
    onChange,
    setLoading = () => {}
  } = props;

  const editorRef = useRef(null);
  const config = useSelector((state) => state.app.config);
  const { TINYMCE_API_KEY } = config;


  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const handleEditorChange = (e, editor) => {
    // console.log(
    //   'Content was updated:',
    //   e.target.getContent()
    // );

    onChange(e.target.getContent());
  }

  return (
    <>
      <Editor
        apiKey={TINYMCE_API_KEY}
        onInit={(evt, editor) => {
          editorRef.current = editor
          setLoading(false)
        }}
        initialValue={initialValue}
        init={{
          // selector: 'textarea',  // change this value according to your HTML
          ...style,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo redo | formatselect | '
            + 'bold italic backcolor | alignleft aligncenter '
            + 'alignright alignjustify | bullist numlist outdent indent | '
            + 'removeformat | image | code | help',

          // image_list: [
          //   {title: 'My image 1', value: 'https://www.example.com/my1.gif'},
          //   {title: 'My image 2', value: 'http://www.moxiecode.com/my2.gif'}
          // ],
          // image_title: true,
          // automatic_uploads: true,
          // file_picker_types: 'image',
          // file_picker_callback: (cb, value, meta) => {
          //   var input = document.createElement('input');
          //   input.setAttribute('type', 'file');
          //   input.setAttribute('accept', 'image/*');

          //   /*
          //     Note: In modern browsers input[type="file"] is functional without
          //     even adding it to the DOM, but that might not be the case in some older
          //     or quirky browsers like IE, so you might want to add it to the DOM
          //     just in case, and visually hide it. And do not forget do remove it
          //     once you do not need it anymore.
          //   */

          //   input.onchange = function () {
          //     var file = this.files[0];

          //     var reader = new FileReader();
          //     reader.onload = function () {
          //       /*
          //         Note: Now we need to register the blob in TinyMCEs image blob
          //         registry. In the next release this part hopefully won't be
          //         necessary, as we are looking to handle it internally.
          //       */
          //       var id = 'blobid' + (new Date()).getTime();
          //       var blobCache = tinymce.activeEditor.editorUpload.blobCache;
          //       var base64 = reader.result.split(',')[1];
          //       var blobInfo = blobCache.create(id, file, base64);
          //       blobCache.add(blobInfo);

          //       /* call the callback and populate the Title field with the file name */
          //       cb(blobInfo.blobUri(), { title: file.name });
          //     };
          //     reader.readAsDataURL(file);
          //   };

          //   input.click();
          // },
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        // onEditorChange={handleEditorChange}
        onChange={handleEditorChange}
      />
      {/* <button onClick={log}>Log editor content</button> */}
    </>
  )
}

export default TinymceWrapper
