import * as Service from '../Service';

export async function getNotificationList() {
  const url = '/api/notification/list';
  const resp = await Service.call('get', url);

  if (resp.status === 1) {
    return resp.data;
  }

  return [];
}

export async function getNotificationGroupSelectionList() {
  const url = '/api/notification/list';
  const resp = await Service.call('get', url);

  if (resp.status === 1) {
    return resp.data;
  }

  return [];
}

export async function getNotificationUserSelectionList() {
  const url = '/api/notification/list';
  const resp = await Service.call('get', url);

  if (resp.status === 1) {
    return resp.data;
  }

  return [];
}

export async function putNotification(putObj) {
  const url = '/api/notification';

  const resp = await Service.call('put', url, putObj);

  return resp;
}

export async function postNotification(putObj) {
  const url = '/api/notification';

  const resp = await Service.call('post', url, putObj);

  return resp;
}


export async function postGlobalNotification(postObj) {
  const url = '/api/notification/global';

  const resp = await Service.call('post', url, postObj);

  return resp;
}

export async function postUserNotification(postObj) {
  const url = '';

  const resp = await Service.call('post', url, postObj);

  return resp;
}

export async function postGroupNotification(postObj) {
  const url = '';

  const resp = await Service.call('post', url, postObj);

  return resp;
}
