import React, { useState, useEffect } from 'react';
import {
  Row, Col, Modal, Table, Typography, Tooltip, Button, Form, DatePicker
} from 'antd';
import {
  SearchOutlined,
  UpOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import 'antd/dist/antd.css';
import _ from 'lodash';
import apiRoutes from '../../core/api';
import * as Helper from '../../core/helper/RenderDisplay';
import moment from 'moment';

const NotificationTable = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [dataList, setDataList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedNotificationTitle, setSelectedNotificationTitle] = useState(false);

  const {callRefresh, setCallRefresh} = props;

  useEffect(() => {
    if (callRefresh) {
      getData();
      setCallRefresh(false);
    }
  }, [callRefresh]);

  const getData = async () => {
    const list = await apiRoutes.getNotificationList();
    setDataList(list);
  }

  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: 'notification_queue_id',
        render: (notification_queue_id, record) => {
          return (
            <span>
              {/* <Tooltip title={t('edit')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setSelectedNotificationTitle(record.title);
                    form.setFieldsValue(record);
                    setModalVisible(true);
                  }}
                />
              </Tooltip> */}
              <Tooltip title={t('push')}>
                <Button
                  shape="circle"
                  style={{ marginRight: 8, color: '#000000' }}
                  icon={<UpOutlined/>}
                  onClick={() => {
                    Modal.confirm({
                      title: t('confirm_push_notification_title'),
                      content: t('confirm_push_notification_msg'),
                      onOk: () => onFinish({notification_queue_id, display_time: moment().unix()}),
                    })
                  }}
                />
              </Tooltip>
            </span>
          )
        }
      },
      {
        title: '標題',
        key: "title",
        dataIndex: 'title',
        render: (value) => t(value),
      },
      {
        title: '內容',
        key: "content",
        dataIndex: 'content',
        width: 500,
        render: (value, data) => (
          <Row align="middle" wrap={false} gutter={[5, 0]}>
            <Col flex="none">
              <SearchOutlined
                onClick={() => {
                  Modal.info({
                    title: t(data.title),
                    width: 800,
                    content: t(value),
                    maskClosable: true,
                  });
                }}
              />
            </Col>
            <Col flex="auto">
              <Typography.Paragraph style={{ margin: 0, maxWidth: 460 }} ellipsis={{ rows: 2 }}>
                {t(value)}
              </Typography.Paragraph>
            </Col>
          </Row>
        ),
      },
      {
        title: t('ctime'),
        key: "ctime",
        dataIndex: 'ctime',
        width: 200,
        render: (value) => Helper.displayMoment(value),
      },
      {
        title: t('published_date'),
        key: "display_time",
        dataIndex: 'display_time',
        width: 200,
        render: (value) => Helper.displayMoment(value),
      },
    ];
    return columns;
  }

  const onFinish = async (formObj) => {
    await apiRoutes.putNotification(formObj);
    closeModal()
  }

  const closeModal = () => {
    setModalVisible(false);
    form.resetFields();
    setCallRefresh(true);
  }

  return (
    <>
      <Table
        tableIDName="notification_id"
        scrollSize="max-content"
        columns={setTableHeader()}
        dataSource={dataList}
      />
      <Modal
        title={selectedNotificationTitle}
        visible={modalVisible}
        onCancel={closeModal}
        footer={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
        >
          <Form.Item name="notification_id" hidden />
          <Form.Item name="display_time" label={t('display_time')}>
            <DatePicker showTime />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
};

export default NotificationTable;
