import React from 'react';
import { Select, Form } from 'antd';
import _ from 'lodash';
import i18n from 'i18next'

import * as values from './valueDefinition';

const { Option } = Select;

export function prefixSelector (disable = false) {
  return (
    <Form.Item name="phonePrefix" noStyle initialValue="+852">
      <Select disabled={disable} style={{ width: 80 }}>
        <Option value="+852">+852</Option>
      </Select>
    </Form.Item>
  )
}

export function generateSelectOptionsWithLabel(
  arrList,
  IDName,
  labelRender = (item) => { return i18n.t(item.label) }
) {
  return _.map(arrList, (item) => {
    return (
      <Option
        key={item[IDName]}
        value={item[IDName]}
        label={labelRender(item)}
      >
        {labelRender(item)}
      </Option>
    )
  })
}

export function generateRadioOptionsWithLabel(arrList, isStr = false) {
  const list = [];
  if (_.isArray(arrList)) {
    _.map(arrList, ({value, label}) => {
      if (_.isUndefined(value) || _.isNull(value)) return;
      list.push({
        value: isStr ? value : _.toInteger(value),
        label: i18n.t(label)
      });
    });
  } else {
    _.forEach(arrList, (value, key) => {
      if (_.isUndefined(value) || _.isNull(value)) return;
      list.push({
        value: isStr ? key : _.toInteger(key),
        label: i18n.t(value)
      });
    });
  }

  return list;
}

// Radio
export const isActiveOptions = () => generateRadioOptionsWithLabel(values.isActive);
export const courseStatusOptions = () => generateRadioOptionsWithLabel(values.courseStatus);
export const isFinishedOptions = () => generateRadioOptionsWithLabel(values.isFinish);
export const attendStatusOptions = () => generateRadioOptionsWithLabel(values.attendanceStatus);
export const paymentStatusOptions = () => generateRadioOptionsWithLabel(values.paymentStatus);
export const genderOptions = () => generateRadioOptionsWithLabel(values.gender, true);

// Checkbox / Select
