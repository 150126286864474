import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  RocketOutlined,
  QrcodeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import { useFetch } from '../../hooks';
import * as Helper from '../../core/helper/RenderDisplay'
const { Option } = Select

const tableIDName = "apply_course_id"

const UserApplyTable = (props) => {
  const { t } = useTranslation();
  const [exportDateRange, setExportDateRange] = useState([
    null,
    null
  ])
  const [limit, setLimit] = useState(null);
  const [reset, setReset] = useState(false)
  const {
    setModalVisible,
    setDataObj,
    callRefresh,
    setCallRefresh,
    userList,
    courseList,
    
  } = props;

  useEffect(() => {
    if (reset) {
      setRefresh(true)
      setExportDateRange([null, null])
      setLimit(null)
      setReset(false)
    }
  }, [reset])
  // const [userList, setUserList] = useState([]);
  const [dataList, setDataList, refresh, setRefresh] = useFetch({
    url: '/api/admin/user/apply/list',
    queryParams: {
      start: exportDateRange[0] ? exportDateRange[0].unix() : null,
      end: exportDateRange[1] ? exportDateRange[1].unix() : null,
      limit: limit || null
    },
    order: { key: ['is_active', 'utime'], value: ['desc', 'desc']},
    callRefresh,
    setCallRefresh
  })


  const setTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        render: (value, record) => {
          return (
            <Row gutter={12}>
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setDataObj(record);
                      setModalVisible(true);
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('user_id'),
        dataIndex: `user_no`,
        sorter: (a, b) => a.user_no.localeCompare(b.user_no),
      },
      {
        title: t('name'),
        dataIndex: `user_id`,
        render: (value) => {
          const user = _.find(userList, { user_id: value });
          return `${user?.first_name ?? ''} ${(user?.last_name ?? '')}`
        },
      },
      {
        title: t('age'),
        dataIndex: 'user_id',
        render: (value) => {
          const user = _.find(userList, { user_id: value });
          return `${user?.age ?? ''}`
        },
      },
      {
        title: t('mobile'),
        dataIndex: 'user_id',
        render: (value) => {
          const user = _.find(userList, { user_id: value });
          return `${user?.phone ?? ''}`
        },
      },
      {
        title: t('course'),
        dataIndex: 'course_id',
        render: (value) => {
          const course = _.find(courseList, {course_id: value});

          if (_.isEmpty(course)) return "-";

          return `${course.course_code} - ${course.course_name}`
        }
      },
      {
        title: t('course_status'),
        dataIndex: 'course_id',
        render: (value) => {
          const course = _.find(courseList, {course_id: value});

          if (_.isEmpty(course)) return "-";

          return Helper.displayCourseStatus(course.status);
        }
      },
      {
        title: t('payment_status'),
        dataIndex: 'status',
        render: (value) => Helper.displayPaymentStatus(value),
      },
    ];
    return columns
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setDataObj({});
          setModalVisible(true);
        }}
      >
        {t('add_user_apply')}
      </Button>
      <Divider />
      <Row gutter={[24, 24]} align="bottom">
        {/* <Col span={6}>
          <div style={{ marginBottom: 6 }}>{t(`range`)}</div>
          <DatePicker.RangePicker
            value={exportDateRange}
            disabled={refresh}
            allowClear={false}
            onChange={(ranges) => {
              const [start_date, end_date] = ranges
              setExportDateRange([start_date.startOf('day'), end_date.endOf('day')])
              setRefresh(true)
            }}
            disabledDate={(time) => {
              return time >= moment().endOf('day')
            }}
            ranges={{
              [t('today')]: [moment().startOf('day'), moment().endOf('day')],
              [t('yesterday')]: [moment().subtract(1, 'day'), moment().endOf('day').subtract(1, 'day')],
              [t('last_week')]: [moment().startOf('day').subtract(1, 'week'), moment().endOf('day')],
              [t('this_month')]: [moment().startOf('month'), moment().endOf('month')],
            }}
          />
        </Col> */}
        <Col span={4}>
          <div style={{ marginBottom: 6 }}>{t(`limit_record`)}</div>
          <Select
            disabled={refresh}
            optionFilterProp="label"
            style={{ width: '100%' }}
            defaultValue={null}
            placeholder= {t('select')}
            value={limit}
            onChange={(value) => {
              setLimit(value)
              setRefresh(true)
            }}
          >
            {_.times(10, (num) => {
              return (
                <Option key={num} value={100 * (num + 1)}>{100 * (num + 1)}</Option>
              )
            })}
          </Select>
        </Col>
        <Col span={6}>
          <SearchBar
            fields={[
              'user_id',
              'first_name',
              'last_name',
              'phone',
            ]}
            data={dataList}
            setData={setDataList}
            refresh={refresh}
            reset={reset}
          />
        </Col>
        <Col>
          <Button
            disabled={refresh}
            onClick={() => {
              setReset(true)
            }}
            type="primary"
            icon={<ClearOutlined />}
          >
            {t('clear_filter')}
          </Button>
        </Col>
      </Row>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={refresh}
      >
        <Table
          locale={{ emptyText: refresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setTableHeader()}
        />
      </Spin>
    </>
  )
}

export default UserApplyTable;
