
import moment from "moment";
import { Tag } from 'antd';
import React from 'react';
import _ from 'lodash';
import i18n from 'i18next'

import * as values from './valueDefinition';
import * as displayFunction from './CommonFunction';
import {defaultDateTimeFormat, defaultDateFormat, defaultTimeFormat} from '../../styles/DisplayFormat';

export function displayPrice(price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const timeConvert = (unixTime, outputFormat) => {
  let time = '';
  if (_.toInteger(unixTime) !== 0) {
    time = moment.unix(unixTime).format(outputFormat);
  } else {
    time = '-'
  }
  return time;
}

export const displayMoment = (unixTime, outputFormat = defaultDateTimeFormat) => {
  return timeConvert(unixTime, outputFormat);
}

export const displayDate = (unixTime, outputFormat = defaultDateFormat) => {
  return timeConvert(unixTime, outputFormat);
}

export const displayTime = (unixTime, outputFormat = defaultTimeFormat) => {
  return timeConvert(unixTime, outputFormat);
}

/** Status render */
export const displayStatus = (value) => {
  const status = _.find(values.isActive, {value});
  if (_.isUndefined(status)) return;
  return <Tag color={status.color}>{i18n.t(status.label)}</Tag>;
}

export const displayIsFinishStatus = (value) => {
  const status = _.find(values.isFinish, {value});
  if (_.isUndefined(status)) return;
  return <Tag color={status.color}>{i18n.t(status.label)}</Tag>;
}

export const displayCourseStatus = (value) => {
  const status = _.find(values.courseStatus, {value});
  if (_.isUndefined(status)) return;
  return <Tag color={status.color}>{i18n.t(status.label)}</Tag>;
}

export const displayAttendanceStatus = (value) => {
  const status = _.find(values.attendanceStatus, {value});
  if (_.isUndefined(status)) return;
  return <Tag color={status.color}>{i18n.t(status.label)}</Tag>;
}

export const displayPaymentStatus = (value) => {
  const status = _.find(values.paymentStatus, {value});
  if (_.isUndefined(status)) return;
  return <Tag color={status.color}>{i18n.t(status.label)}</Tag>;
}

export default displayFunction;
