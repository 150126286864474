
import moment from "moment";
import { Tag } from 'antd';
import React from 'react';
import _ from 'lodash';
import i18n from 'i18next'

export function passwordChecking (password1, password2) {
  return password1 === password2;
}

export function hideMobile(mobile) {
  let mobileLength = _.toString(mobile).length;
  let displayLength = 8;
  if (displayLength > mobileLength) {
    return _.toString(mobile).substr(0, 4);
  }
  let prefix = _.toString(mobile).substr(0, 5);
  let suffix = _.toString(mobile).substr(mobile.length - 3);
  return `${prefix}${''.padEnd(mobileLength - displayLength, '*')}${suffix}`;
}

export function hideEmail(email) {
  let emailLength = _.toString(email).length;
  let displayLength = 10;
  if (displayLength > emailLength) {
    return _.toString(email).substr(0, 4);
  }
  let prefix = _.toString(email).substr(0, 4);
  let suffix = _.toString(email).substr(email.length - 6);
  return `${prefix}${''.padEnd(emailLength - displayLength, '*')}${suffix}`;
}
