import React, { useCallback, useEffect, useState } from 'react';
import { Input, Col, Row } from 'antd'
import Fuse from 'fuse.js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const FUSE_OPTIONS = {
  includeScore: true,
  findAllMatches: true,
  threshold: 0, // exact match
  keys: [], // fields
  ignoreLocation: true, // also search within strings not only from start
}

let timeout;
const debounce = (func, delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(func, delay);
};

const SearchBar = ({
  setData = () => {},
  data = [],
  refresh,
  fields = [],
  inputStyles = {},
  horizontal = false,
  size = 'normal', // small, normal, large
  reset = false
}) => {
  const { t } = useTranslation()
  const [fuse, setFuse] = useState([])
  const [value, setValue] = useState(null)

  const getInitialData = () => {

    if (!refresh) {
      return
    }
    if (_.isEmpty(data)) {
      setFuse([]);
      return
    }
    FUSE_OPTIONS.keys = fields;
    setFuse(new Fuse(data, FUSE_OPTIONS));
  };

  useEffect(() => {
    getInitialData()
  }, [refresh, data])

  useEffect(() => {
    if (reset) {
      setValue(null)
    }
  }, [reset])


  const filterData = ({
    keyword = null
  }) => {
    debounce(() => {
      if (_.isEmpty(fuse)) return;

      let list = fuse
      let { _docs } = list
      if (!_.isEmpty(keyword)) {
        _docs = list.search(keyword).map((result) => result.item)
      }
      setData(_docs)
    }, 400)
  }

  const onChange = (event) => {
    const keyword = event.target.value;
    setValue(keyword)
    filterData({ keyword })
  }

  const getSize = () => {
    switch (size) {
      case 'small': return 8;
      case 'normal': return 12;
      case 'large': return 24;
      default: return 24;
    }
  }

  return (
    <Col span={getSize()}>
      <Row align="middle">
        <Col
          style={{
            margin:
            horizontal ? '0 12px 0px 0px' : '0px 0px 6px 0px'
          }}
          span={horizontal ? 'auto' : 24}
        >
          {t('search')}
        </Col>
        <Col span={horizontal ? 16 : 24}>
          <Input
            value={value}
            disabled={refresh}
            placeholder={t('please_input_keyword')}
            style={{ ...styles.input, ...inputStyles }}
            onChange={onChange}
          />
        </Col>
      </Row>
    </Col>
  )
};

const styles = {
  input: {
    minWidth: 270, width: '50%'
  }
}

export default SearchBar;
