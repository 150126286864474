import React, { useState, useEffect } from 'react';
import {
  Button, Divider, Table, Tag, Tooltip, Row, Col,
  Select, DatePicker, Spin, List
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  ClearOutlined,
  RocketOutlined,
  QrcodeOutlined,
  SearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import SearchBar from '../SearchBar';
import { useFetch } from '../../hooks';
import * as Helper from '../../core/helper/RenderDisplay'
import { Link, useHistory } from 'react-router-dom';

import apiRoutes from '../../core/api';
import CourseLessonModal from './CourseLessonModal';

const { Option } = Select

const tableIDName = "lesson_id";

const CourseLessonTable = (props) => {
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [dataObj, setDataObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const {callRefresh, setCallRefresh, courseCode, course} = props;

  useEffect(() => {
    if (callRefresh) getData();
  }, [callRefresh, courseCode]);

  const getData = async () => {
    const result = await apiRoutes.getCourseLessonByCourseCode(courseCode);
    setDataList(result);
  }

  const setLessonTableHeader = () => {
    const columns = [
      {
        title: t('operation'),
        dataIndex: tableIDName,
        key: tableIDName,
        render: (value, record) => {
          return (
            <Row gutter={12}>
              <Col>
                <Tooltip title={t('edit')}>
                  <Button
                    shape="circle"
                    style={{ color: '#000000' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setDataObj(record);
                      setModalVisible(true);
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          )
        }
      },
      {
        title: t('date'),
        dataIndex: 'start_datetime',
        render: (value) => Helper.displayDate(value),
      },
      {
        title: t('start_time'),
        dataIndex: 'start_datetime',
        render: (value) => Helper.displayTime(value),
      },
      {
        title: t('end_time'),
        dataIndex: 'end_datetime',
        render: (value) => Helper.displayTime(value),
      },
      // {
      //   title: t('is_finished'),
      //   dataIndex: 'is_finished',
      //   render: (value) => Helper.displayIsFinishStatus(value),
      // },
      {
        title: t('course_status'),
        dataIndex: 'is_active',
        render: (value) => Helper.displayStatus(value),
      },
    ];
    return columns
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setDataObj({});
          setModalVisible(true);
        }}
      >
        {t('add_lesson')}
      </Button>
      <Divider />
      <Spin
        indicator={(
          <svg
            className="spinner"
            viewBox="0 0 50 50"
          >
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        )}
        spinning={callRefresh}
      >
        <Table
          locale={{ emptyText: callRefresh && (<div style={{ height: 166 }} />) }}
          rowKey={tableIDName}
          scroll={{ x: 'max-content' }}
          dataSource={dataList}
          columns={setLessonTableHeader()}
        />
      </Spin>


      <CourseLessonModal
        dataObj={course}
        lessonObj={dataObj}
        setDataObj={setDataObj}
        setLessonObj={setDataObj}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        setCallRefresh={setCallRefresh}
      />
    </>
  )
}

export default CourseLessonTable;
